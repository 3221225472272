import 'jquery';
import {gsap} from 'gsap';
import ScrollToPlugin from "ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);


export default {
  dropdownSelector: '#product-selector',
  init() {
    $(document).ready($.proxy((e) => {
      this.onDocReady();
    }, this));
  },
  onDocReady() {
    var self = this;
    this.$productDropdown = $(this.dropdownSelector);
    this.$pWrap = $('.page-wrap');
    this.$jumpLink = $('.product-jump-link');

    this.$productDropdown.on('change', (e) => {
      var $this = $(e.currentTarget);
      const val = $this.val();
      const $product = $('#' + val);
      if ($product.length) {
        gsap.set($product.siblings(), {autoAlpha: 0, height: 0});
        gsap.set($product, {height: 'auto'});
        gsap.fromTo($product, {autoAlpha: 0, y: 100}, {autoAlpha: 1, y: 0, duration: 1, ease: "expo.out", onComplete: () => {}});
        const offset = self.$pWrap.find('.page-section.hero').outerHeight(true) + self.$pWrap.find('.page-section.product-content-section').outerHeight(true) + self.$pWrap.find('.product-selector-wrap').outerHeight(true);
        gsap.to(self.$pWrap[0], {scrollTo: offset, duration: 1, ease: "expo.out"});
      }
    });

    this.$jumpLink.on('click', (e) => {
      e.preventDefault();
      const offset = self.$pWrap.find('.page-section.hero').outerHeight(true) + self.$pWrap.find('.page-section.product-content-section').outerHeight(true);
      gsap.to(self.$pWrap[0], {scrollTo: offset, duration: 1.5, ease: "expo.out"});
    });
  }
}
