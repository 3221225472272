import 'jquery';
import Player from '@vimeo/player';

export default {
  vidClass: 'vimeo-video',
  init() {
    this.vimeoIframes = document.getElementsByClassName(this.vidClass);
    this.setupVimeoPlayers();
  },
  setupVimeoPlayers() {
    for (var i = 0; i < this.vimeoIframes.length; i++) {
      var $frame = $(this.vimeoIframes[i]);
      if ($frame.hasClass('lazyload') && $frame[0].nodeName == "IFRAME") {
        $frame.on('lazyloaded', $.proxy(function() {
          console.log("iframe loaded!!");
          var src = $frame.attr('data-custom-src');
          $frame.removeAttr('data-custom-src');
          $frame.attr('src', src);
          var id = $frame[0]['id'];
          var player = new Player(id, {
            id: id,
            width: 1280,
            height: 720,
          });
          window.vidPlayers.vimeo[id] = player;
        }, this));
      }
    }
  },
}
