import 'jquery';
import {gsap} from 'gsap';

export default {
  modalLinkSelector: '.video-modal-link',
  loadVidsSelector: '.vid-load-link',
  sectionSelector: '.page-section.training-lib',
  vidItemSelector: '.training-video-item',
  vidContainerSelector: '.videos-container > .row',
  vidsPerPage: 4,
  activePage: 2,
  fetchedVids: [],
  init() {
    $(document).ready($.proxy((e) => {
      this.onDocReady();
    }, this));
  },
  onDocReady() {
    var self = this;
    this.$modal = $('#layoutModal');
    this.$loadMoreLink = $(this.loadVidsSelector);
    this.$vidContainer = $(this.vidContainerSelector);
    this.$section = $(this.sectionSelector);
    this.$section.on('click', this.modalLinkSelector, (e) => {
      e.preventDefault();
      var $this = $(e.currentTarget);
      const $vidItem = $this.closest(this.vidItemSelector);
      const ytID = $this.attr('data-vid-id');
      const $ytIframe = $vidItem.find('.embed-responsive').clone();
      self.$modal.find('.modal-header').html('<h5 class="mb-0">' + $vidItem.find('.video-title').html() + '</h5>');
      self.$modal.find('.modal-body').html($ytIframe);
      self.$modal.addClass('training-video');
      self.$modal.modal('show');
    });
    this.$modal.on('hidden.bs.modal', (e) => {
      if (self.$modal.hasClass('training-video')) {
        const $embed = self.$modal.find('.modal-body').find('.embed-responsive');
        $embed.remove();
        // const dID = $embed.attr('data-id');
        // const $vidContainer = self.$section.find(self.vidItemSelector).filter('[data-id="' + dID + '"]');
        // $vidContainer.find('.vid-embed-wrap').html($embed);
        self.$modal.removeClass('training-video');
      }
    });

    this.$loadMoreLink.click((e) => {
      e.preventDefault();
      const $this = $(e.currentTarget);
      self.getMoreVideos();
    });
  },
  getMoreVideos() {
    const self = this;
    const baseUrl = window.location.origin + '/wp-json/wp/v2/training_video';
    $.ajax({
      url: baseUrl + '?page=' + this.activePage + '&per_page=' + this.vidsPerPage,
    }).done($.proxy((data) => {
      console.log(data);
      if ($.isArray(data) && data.length > 0) {
        $.merge(this.fetchedVids, data);
        if (data.length == this.vidsPerPage) {
          this.activePage++;
        } else {
          this.$loadMoreLink.hide();
        }
      }
      this.renderVideos();
    }, this));
    return this;
  },
  getVideoStr(video) {
    const ytID = video.acf.youtube_id;
    const thumbUrl = video.acf._thumbnail_id.url;
    let title = video.title.rendered;
    if (video.acf.video_title && video.acf.video_title.length) {
      title = video.acf.video_title;
    }
    let vidStr = `<div class="col-12 col-md-6 p-gw training-video-item" data-id="` + video.id + `">
      <div class="card video-wrap shadow">
        <div class="card-body">
          <a href="#" class="d-block video-modal-link rounded" data-vid-id="` + ytID + `">
            <img src="` + thumbUrl + `" data-src="` + thumbUrl + `" class="lazyload video-thumb w-100" />
            <div class="play-btn-wrap">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.29 59.29">
                <defs>
                  <style>
                    .vid-play-btn .cicle-bg {
                      fill:#9d958c;
                    }
                    .vid-play-btn .triangle-inner {
                      fill:#f5c65d;
                    }
                    .vid-play-btn .white {
                      fill:#fff;
                    }
                  </style>
                </defs>
                <g id="vid-play-btn" class="vid-play-btn" data-name="vid-play-btn">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <circle class="gray svg-path circle-bg" cx="32.12" cy="31.97" r="26.17"/>
                    <path class="yellow svg-path triangle-inner" d="M26.23,23a1.09,1.09,0,0,0-.59.17,1.14,1.14,0,0,0-.59,1V42.85a1.18,1.18,0,0,0,1.83,1L42,33.81a1.16,1.16,0,0,0,.53-1,1.18,1.18,0,0,0-.6-1L26.82,23.15A1.17,1.17,0,0,0,26.23,23Z"/>
                    <path class="white svg-path circle-outer" d="M29.65,59.29A29.65,29.65,0,1,1,59.29,29.65,29.68,29.68,0,0,1,29.65,59.29Zm0-55.82A26.18,26.18,0,1,0,55.82,29.65,26.21,26.21,0,0,0,29.65,3.47Z"/>
                    <path class="white svg-path triangle-outer" d="M41.68,25.79,26.54,17.14a4.65,4.65,0,0,0-7,4V39.85a4.66,4.66,0,0,0,7.23,3.88l15.14-10a4.66,4.66,0,0,0-.26-7.92Zm-1.66,5-15.14,10a1.18,1.18,0,0,1-1.83-1V21.18a1.14,1.14,0,0,1,.59-1,1.09,1.09,0,0,1,.59-.17,1.17,1.17,0,0,1,.59.16L40,28.8a1.18,1.18,0,0,1,.6,1A1.16,1.16,0,0,1,40,30.81Z"/>
                  </g>
                </g>
              </svg>
            </div>
          </a>
        </div>
        <div class="video-footer text-center pb-3">
          <p class="wt-700 text-primary video-title mb-1">` + title + `</p>`;
          if (video.acf.video_blurb && video.acf.video_blurb.length) {
            vidStr += `<div class="video-desc">` + video.acf.video_blurb + `</div>`;
          }
          vidStr += `
        </div>
        <div class="vid-embed-wrap d-none">
          <div class="embed-responsive embed-responsive-16by9"  data-id="` + video.id + `">
            <iframe id="traning-video-` + video.id + `" class="embed-responsive-item youtube-video" src="https://www.youtube.com/embed/` + ytID + `" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>`;
      return vidStr;
  },
  renderVideos() {
    // this.$vidContainer.empty();
    this.fetchedVids.map((vid) => {
      const vidStr = this.getVideoStr(vid);
      const $vid = $(vidStr);
      this.$vidContainer.append($vid);
      gsap.fromTo($vid, {y: -100, opacity: 0}, {y: 0, opacity: 1, duration: 0.7, ease: "expo.out"});
    });
    return this;
  }
}
