import 'jquery';
import { gsap } from "gsap";

function Header(selector) {

  const header = {
    activeClass: "active",
    navToggleSelector: ".menu-toggle",
    navAnimatingClass: "nav-animating",
    navOpenClass: "nav-open",
    overlaySelector: ".site-overlay",
    navLinkAccordionToggle: ".level-toggle",
    accordionOpenClass: "open",
    subMenuSelector: ".level-container",
    menuSelector: ".header-inner",
    searchSelector: '.search-form-wrapper',
    initialize(selector) {
      this.$ = jQuery;
      this.$el = this.$(selector);
      this.el = this.$el[0];
      this.$w = this.$(window);
      this.$h = this.$('html');
      this.$overlay = this.$(this.overlaySelector);
      this.bindEvents();
    },
    bindEvents() {

      this.$h.on('click', this.navToggleSelector, this.$.proxy(this.onNavToggleClick, this));
      this.$h.on('click', this.overlaySelector, this.$.proxy(this.onNavToggleClick, this));
      this.$h.on('click', this.navLinkAccordionToggle, this.$.proxy(this.onNavAccordionToggle, this));
      this.$h.on('transitionend', this.menuSelector, this.$.proxy(this.transitionEnded, this));
      this.$h.on('webkitTransitionEnd', this.menuSelector, this.$.proxy(this.transitionEnded, this));
      this.$h.on('mozTransitionEnd', this.menuSelector, this.$.proxy(this.transitionEnded, this));
      this.$h.on('oTransitionEnd', this.menuSelector, this.$.proxy(this.transitionEnded, this));
      this.$h.on('transitionend', this.menuSelector, this.$.proxy(this.transitionEnded, this));
      this.$h.on('mouseenter', this.searchSelector, this.$.proxy(function(e) {
        $(e.currentTarget).find('input').focus();
      }, this));
      this.$w.resize(this.$.proxy(function(e) {
        this.onEveryResize(e);
        setTimeout(this.$.proxy(function() {
          this.onResize(e);
        }, this), 100);
      }, this));
      return this;
    },
    onNavToggleClick(e) {
      e.preventDefault();
      if (!this.$h.hasClass(this.navOpenClass)) {
          this.openMenu();
      } else {
        this.closeMenu();
      }
    },
    openMenu() {
      this.$h.addClass(this.navAnimatingClass).addClass(this.navOpenClass);
      return this;
    },
    closeMenu() {
      this.$h.addClass(this.navAnimatingClass).removeClass(this.navOpenClass);
      return this;
    },
    transitionEnded(e) {
      //if ((e.currentTarget === e.target) && $(e.currentTarget).is(this.menuSelector)) {
        this.removeTransitionClasses();
      // }
      return this;
    },
    removeTransitionClasses() {
      this.$h.removeClass(this.navAnimatingClass);
    },
    onNavAccordionToggle(e) {
      e.preventDefault();
      var $this = $(e.currentTarget),
        $parent = $this.closest('li'),
        $children,
        $feature;
      if ($parent.hasClass(this.accordionOpenClass)) {
        $parent.removeClass(this.accordionOpenClass);
        $children = $parent.find(this.subMenuSelector).children('ul').children('li');
        $feature = $parent.find(this.subMenuSelector).children('.link-feature');
        gsap.fromTo($children, {
          opacity: 1,
          x: 0,
        }, {
          opacity: 0,
          x: -20,
          ease: "expo.out",
          duration: 0.3,
          stagger: 0.1,
        });

        if ($feature.length) {
          gsap.fromTo($feature, {
            y: 0,
            opacity: 1,
          },
          {
            y: 60,
            opacity: 0,
            ease: "expo.out",
            duration: 0.6,
          });
        }

        gsap.to($parent, {
          height: $this.closest('.level-item-inner').outerHeight(),
          ease: "expo.out",
          duration: 0.3,
        });

      } else {
        $parent.addClass(this.accordionOpenClass);
        var height = $this.closest('.level-item-inner').outerHeight() + $parent.find(this.subMenuSelector).outerHeight();
        $children = $parent.find(this.subMenuSelector).children('ul').children('li');
        $feature = $parent.find(this.subMenuSelector).children('.link-feature');
        gsap.fromTo($children, {
          opacity: 0,
          x: -20,
        }, {
          opacity: 1,
          x: 0,
          ease: "expo.out",
          duration: 0.3,
          stagger: 0.1,
        });

        if ($feature.length) {
          gsap.fromTo($feature, {
            y: 60,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            ease: "expo.out",
            duration: 0.6,
          });
        }

        gsap.to($parent, {
          height: height,
          ease: "expo.out",
          duration: 0.3,
          onComplete: this.$.proxy(function() {
            $parent.height('auto');
          }, this),
        });
      }
      return this;
    },
    onEveryResize() {
      var desktop_width = 1200;
      if (this.$w[0].innerWidth >= desktop_width) {
        this.$h.removeClass(this.navOpenClass).removeClass(this.navAnimatingClass);
        var $navAccordionLis = this.$(this.navLinkAccordionToggle).closest('li').removeClass(this.navOpenClass).removeAttr("style");
      }
      return this;
    },
    isKeyEventSafe(e) {
      if (document.activeElement.tagName.toLowerCase() !== "input" &&
        document.activeElement.tagName.toLowerCase() !== "textarea" &&
        document.activeElement.tagName.toLowerCase() !== "select" && !e.shiftKey &&
        this.isActive()) {
        return true;
      }
      return false;
    },
    onResize() {
      return this;
    },
  };

  return header;
}

function HeaderMenu(selector) {
  let header = new Header(selector);

  header.initialize(selector);
  return header;
}

export default HeaderMenu;
