// import external dependencies

import '@babel/polyfill';
import 'jquery';
import 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/native-loading/ls.native-loading';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import { gsap, TweenMax, TimelineMax, TweenLite, TimelineLite } from "gsap";
import ScrollToPlugin from "ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

// import local dependencies
import Router from './util/Router';

import common from './routes/common';
import products from './routes/templateProducts';

window.vidPlayers = {
  youtube: {},
  vimeo: {},
  self: {},
};

// Custom reduce function for jQuery. The opposite of jQuery.extend
window.jQuery.reduce = function(obj1, obj2) {
  for (var k in obj2) {
    if (obj1.hasOwnProperty(k) && obj2.hasOwnProperty(k)) {
      if (typeof obj1[k] == "object" && typeof obj2[k] == "object") {
         reduce(obj1[k], obj2[k]);
      }
      else delete obj1[k];
    }
  }
  return obj1;
}

function getHeaderOffset() {
  let offset = $('#site-header').outerHeight();
  if ($('body').hasClass('admin-bar')) {
    offset += $('#wpadminbar').outerHeight();
  }
  return offset;
}
window.getHeaderOffset = getHeaderOffset;

function loadImage($el, imgData, callback) {
  if (imgData && $el && $el.length) {
    let $img = $(new Image());

    $img.on('load', function() {
      if (typeof callback == "function") {
        callback.call($el, imgData);
      }
    });

    $img.attr('src', imgData['url']);
    return imgData['url'];
  }
}
window.loadImage = loadImage;

function applyImageCSS($el, imgData) {
  gsap.to($el, {opacity: 0, ease: "expo.out", duration: 0.3, onComplete: function() {
    let newCss = {
      "background-image": "url(" + imgData['url'] + ")",
      "background-position": imgData['pos'],
      "background-repeat": imgData['repeat'],
      "background-origin": imgData['orig'],
      "background-clip": imgData['clip'],
      "background-attach": imgData['attach'],
      "background-size": imgData['size'],
    };
    $el.css(newCss);
    gsap.to($el, {opacity: 1, ease: "expo.out", duration: 0.3});
  }});
}
window.applyImageCSS = applyImageCSS;

function debounceResize(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
window.debounceResize = debounceResize;

function cleanupEditorTags($contentBoxes) {
  var $imgs = $contentBoxes.find('> p > img');
  $imgs.each(function() {
    var $this = $(this);
    var $parent = $this.parent();
    $parent.replaceWith($this);
  });

  var $iframes = $contentBoxes.find('iframe');
  $iframes.each(function() {
    var $this = $(this);
    var src = $this.attr('src');
    if (src && src.length && src.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(?:(?:youtu\.?be)|(?:youtube\.com)|(?:vimeo\.com))/gm))  {
      var $parent = $this.parent();
      if(!$parent.is('.post-content')) {
        $this.detach();
        var $vidWrap = $('<div class="embed-responsive embed-responsive-16by9"></div>');
        $vidWrap.append($this);
        $parent.replaceWith($vidWrap);
      } else if (!$parent.hasClass('embed-responsive')) {
        $this.wrap('<div class="embed-responsive embed-responsive-16by9"></div>');
      }
    }
  });

  var $ps = $contentBoxes.find('p');
  $ps.each(function() {
    var $this = $(this);
    // console.log($this.is(':empty'), $this.text());
    if ($this.is(':empty') || $this.text().match(/^\s*$/)) {
      $this.addClass('mb-0');
      // $this.remove();
    }
  });
}
window.cleanupEditorTags = cleanupEditorTags;

function loadSetupScript(path, id) {
  const url = mainJs.siteUrl + mainJs.activeTheme.dist.scripts + '/setup/' + path;
  if (!$('script[src="' + url + '"]').length) {
    var head = document.getElementsByTagName('head')[0];
    var js = document.createElement("script");
    js.id = (id || '');
    js.type = "text/javascript";
    js.src = url;
    head.appendChild(js);
  }
}
window.loadSetupScript = loadSetupScript;


// Load Events
(function($) {

  /** Populate Router instance with DOM routes */
  const routes = new Router({
    // All pages
    common,
    products,
  });

  routes.loadEvents();

  $(document).ready(function() {

    const $w = $(window);

    // Trigger resize on page load;
    $w.on('load', () => {
      $w.resize();
    });
  });


})(jQuery);

(function () {

  //Find Typekit inlined CSS and add/repace the font-face:swap property to the @font-face rules.
  if (typeof MutationObserver === 'undefined') {
      return;
  }
  var fixFontDisplay = function () {
      // inject font-display option into typekit fonts
      var style = document.getElementById('typekit-font-css');
      if (style && style.innerText) {
        const matches = style.innerText.match(/font-display:\w+;/g);

        if (style.innerText.indexOf('tablet-gothic-semi-condensed') !== -1
            && (matches.length || style.innerText.indexOf('@font-face{font-display:swap;') === -1)) {

            if (matches.length) {
              style.innerText = style.innerText.replace(/font-display:\w+;/g, 'font-display:swap;');
            } else {
              style.innerText = style.innerText.replace(/@font-face\s*{/g, '@font-face{font-display:swap;');
            }
        }
      }
  };
  var observer = new MutationObserver(function (mutationsList, observer) {
      for (var i = 0; i < mutationsList.length; i++) {
          fixFontDisplay();
      }
  });
  observer.observe(
      document.getElementsByClassName('footer-scripts')[0],
      {attributes: false, childList: true, subtree: false}
  );
  window.fixFontObserver = observer;
})();
