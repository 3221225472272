export default function(url, type, callback) {
  var loadedUrls = {};

  function loadFile(url, type, callback) {

    if(!Object.keys(loadedUrls).includes(url)) {
      // Request the JSON file
      var ajax = new XMLHttpRequest();
      ajax.open("GET", url.replace('.' + type, '') + "." + type, true);
      ajax.send();

      // Append the SVG to the target
      if (typeof callback == 'function') {
        ajax.onload = function(e) {
          loadedUrls[url] = {
            url: url,
            type: type,
            response: ajax.responseText,
          };
          callback(ajax.responseText);
        }
      }
    } else {
      callback(loadedUrls[url].response);
    }
  }

  loadFile(url, type, callback);
}
