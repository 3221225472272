import 'jquery';

var mapStyles = [
  {elementType: 'geometry', stylers: [{color: '#ebe3cd'}]},
  {elementType: 'labels.text.fill', stylers: [{color: '#523735'}]},
  {elementType: 'labels.text.stroke', stylers: [{color: '#f5f1e6'}]},
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [{color: '#c9b2a6'}]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'geometry.stroke',
    stylers: [{color: '#dcd2be'}]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [{color: '#ae9e90'}]
  },
  {
    featureType: 'landscape.natural',
    elementType: 'geometry',
    stylers: [{color: '#dfd2ae'}]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{color: '#dfd2ae'}]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{color: '#93817c'}]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [{color: '#a5b076'}]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{color: '#447530'}]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{color: '#f5f1e6'}]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [{color: '#fdfcf8'}]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{color: '#f8c967'}]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{color: '#e9bc62'}]
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [{color: '#e98d58'}]
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.stroke',
    stylers: [{color: '#db8555'}]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{color: '#806b63'}]
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [{color: '#dfd2ae'}]
  },
  {
    featureType: 'transit.line',
    elementType: 'labels.text.fill',
    stylers: [{color: '#8f7d77'}]
  },
  {
    featureType: 'transit.line',
    elementType: 'labels.text.stroke',
    stylers: [{color: '#ebe3cd'}]
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [{color: '#dfd2ae'}]
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [{color: '#b9d3c2'}]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{color: '#92998d'}]
  }
];

var mapSection = {
  secMaps: [],
  init() {
    $(document).ready($.proxy(this.onDocReady, this));
    return this;
  },
  onDocReady() {
    this.$w = $(window);
    this.initMaps();
    this.$w.on('load', $.proxy(this.onWinLoad, this));
    // this.$w.on('resize', $.proxy(this.onResize, this));
    return this;
  },
  onWinLoad() {
    this.$w.resize();
    return this;
  },
  onResize() {
    return this;
  },
  initMaps() {
    var self = this;
    const $maps = $('.map-section .map-div');
    if ($maps && $maps.length) {
      $maps.each(function() {
        const $this = $(this);
        const $lat = $this.attr('data-lat');
        const $lng = $this.attr('data-lng');
        const pos = {lat: Number($lat), lng: Number($lng)};
        let opts = {
          center: pos,
          zoom: 14,
          scrollwheel: false,
          styles: mapStyles
        };
        let userOpts = $this.attr('data-opts');
        if (userOpts) {
          userOpts = JSON.parse(userOpts);
          opts = $.extend(opts, userOpts);
        }
        const map = new google.maps.Map(this, opts);
        var marker = new google.maps.Marker({position: pos, map: map});
        var mapObj = {map: map, markers: [marker]};
        self.secMaps.push(mapObj);
      });
    }
    return this;
  },
}

export default mapSection;
