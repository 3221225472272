import 'jquery';
import 'jquery.gmap3';
const OverlappingMarkerSpiderfier = require('oms').OverlappingMarkerSpiderfier;
import 'bootstrap';
import parsleyjs from 'parsleyjs';
import LoadFile from '../util/LoadFile';
import usMapBorder from 'usMapBorder';
import azMapBorder from 'azMapBorder';
import arMapBorder from 'arMapBorder';
import kaMapBorder from 'kaMapBorder';
import caMapBorder from 'caMapBorder';
import neMapBorder from 'neMapBorder';
import nvMapBorder from 'nvMapBorder';
import okMapBorder from 'okMapBorder';
import orMapBorder from 'orMapBorder';
import txMapBorder from 'txMapBorder';
import utMapBorder from 'utMapBorder';
import allStatesBorder from 'allStatesBorder';
import googleBorder from 'googleBorder';
import jalc from 'jalc';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

var metersInMile = 1609.34;
// var primary = '#A06852';
// var primaryHover = '#7e5241';
// var secondary = '#24ABC7';
// var secondaryHover = '#1c869c';
// var tertiary = '#607333';
// var tertiaryHover = '#7d9643';

var primary = '#24ABC7';
var primaryHover = '#1c869c';
var secondary = '#7d9643';
var secondaryHover = '#607333';
var tertiary = '#A06852';
var tertiaryHover = '#7e5241';
window.stores = [];

$.gmap3(false); // disable gmap3 loader

var mapStyles = [
  {elementType: 'geometry', stylers: [{color: '#ebe3cd'}]},
  {elementType: 'labels.text.fill', stylers: [{color: '#523735'}]},
  {elementType: 'labels.text.stroke', stylers: [{color: '#f5f1e6'}]},
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [{color: '#c9b2a6'}]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'geometry.stroke',
    stylers: [{color: '#dcd2be'}]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [{color: '#ae9e90'}]
  },
  {
    featureType: 'landscape.natural',
    elementType: 'geometry',
    stylers: [{color: '#dfd2ae'}]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{color: '#dfd2ae'}]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{color: '#93817c'}]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [{color: '#a5b076'}]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{color: '#447530'}]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{color: '#f5f1e6'}]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [{color: '#fdfcf8'}]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{color: '#f8c967'}]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{color: '#e9bc62'}]
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [{color: '#e98d58'}]
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.stroke',
    stylers: [{color: '#db8555'}]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{color: '#806b63'}]
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [{color: '#dfd2ae'}]
  },
  {
    featureType: 'transit.line',
    elementType: 'labels.text.fill',
    stylers: [{color: '#8f7d77'}]
  },
  {
    featureType: 'transit.line',
    elementType: 'labels.text.stroke',
    stylers: [{color: '#ebe3cd'}]
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [{color: '#dfd2ae'}]
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [{color: '#b9d3c2'}]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{color: '#92998d'}]
  }
];

export default {
  theMap: null,
  mapStyle: mapStyles,
  bounds: null,
  mapCenter: {
    lat: 33.492581,
    lng: -112.075192,
  },
  resultsMapCenter: null,
  containerSelector: '.sections-wrap',
  resultsSelector: '.search-results-container',
  resultSelector: '.store-location-item',
  stateFilterSelector: '.state-filter-dropdown',
  mapSelector: '.search-locations-map',
  autoInputSelector: '.autocomplete-input',
  searchFormSelector: '.store-location-search-form',
  filterFormSelector: '.search-locations-filter',
  mapToggleSelector: '.map-toggle-btn',
  mapCollapseSelector: 'search-map-collapse',
  fullListSelector: '.full-list-btn',
  storeListLinkSelector: '.store-list-icon-link',
  userLocationSelector: '.user-location-link',
  userMarkerSet: false,
  restrictResults: false,
  userCenter: null,
  activeState: false,
  activeLocations: [],
  allStores: [],
  allMarkers: {},
  $autoInput: $(),
  stateBorders: {
    AZ: azMapBorder,
    AR: arMapBorder,
    KA: kaMapBorder,
    CA: caMapBorder,
    NE: neMapBorder,
    NV: nvMapBorder,
    OK: okMapBorder,
    OR: orMapBorder,
    TX: txMapBorder,
    UT: utMapBorder
  },
  stateBounds: {
    AZ: new google.maps.LatLngBounds(),
    AR: new google.maps.LatLngBounds(),
    KA: new google.maps.LatLngBounds(),
    CA: new google.maps.LatLngBounds(),
    NE: new google.maps.LatLngBounds(),
    NV: new google.maps.LatLngBounds(),
    OK: new google.maps.LatLngBounds(),
    OR: new google.maps.LatLngBounds(),
    TX: new google.maps.LatLngBounds(),
    UT: new google.maps.LatLngBounds(),
  },
  autoServices: {},
  placeServices: {},
  dataLayers: [],
  defStateOverlayStyles: {
    fillColor: '#000',
    fillOpacity: 0.05,
    strokeColor: '#000',
    strokeOpacity: 0.2,
    strokeWeight: 2,
  },
  iconAnchor: new google.maps.Point(16, 32),
  iconSize: new google.maps.Size(32, 32),
  labelOrigin: new google.maps.Point(16, 16),
  init() {

    this.$w = $(window);

    if (!$(document.body).hasClass('password-protected')) {
      $($.proxy(this.onDocReady, this));
      this.$w.on('load', $.proxy(this.onWinLoad, this));
    }

    return this;
  },
  onMapReady() {

    this.addStateOverlays();

    this.theMap.data.setStyle(this.defStateOverlayStyles);

    this.iw = new google.maps.InfoWindow({maxWidth: 280});

    // this.oms = new OverlappingMarkerSpiderfier(this.theMap, {
    //   keepSpiderfied: true,
    //   markersWontMove: false,
    //   markersWontHide: false,
    //   ignoreMapClick: true,
    //   nearbyDistance: 42,
    //   legWeight: 1.5,
    //   circleFootSeparation: 42,
    //   spiralFootSeparation: 36,
    //   spiralLengthStart: 18,
    //   spiralLengthFactor: 6,
    // });

    // // var mti = google.maps.MapTypeId;
    // this.oms.legColors.usual = {
    //   HYBRID: '#a5b08c',
    //   TERRAIN: '#607333',
    //   SATELLITE: '#a5b08c',
    //   ROADMAP: '#607333',
    // };

    // this.oms.legColors.highlighted = {
    //   HYBRID: '#f5c65d',
    //   TERRAIN: '#f5c65d',
    //   SATELLITE: '#f5c65d',
    //   ROADMAP: '#f5c65d',
    // };

    // this.oms.addListener('spiderfy', $.proxy(function (sMarkers, untouched) {
    //   sMarkers.map((marker, i) => {
    //     this.setMarkerColors(marker).setMarkerLabel(marker);
    //   });
    // }, this));

    // this.oms.addListener('unspiderfy', $.proxy(function (uMarkers, untouched) {
    //   uMarkers.map((marker, i) => {
    //     this.setMarkerColors(marker).setMarkerLabel(marker);
    //   });
    // }, this));

    // this.oms.addListener('format', $.proxy(function(fMarker, status) {
    //   this.onMapMarkerFormat(fMarker, status);
    //   // console.log(fMarker, status);
    // }, this));

    google.maps.event.addListener(this.theMap, 'zoom_changed', $.proxy(this.changeStateOpacity, this));
    google.maps.event.addListener(this.theMap, 'click', $.proxy(function(e) {
      if (this.iw.getMap() && this.iw.getContent()) {
          this.closeIW();
      }
    }, this));

    this.theMap.data.addListener('click', $.proxy(function(e) {
      if (this.iw.getMap() && this.iw.getContent()) {
          this.closeIW();
      }
    }, this));

    return this;
  },
  onDocReady() {
    var self = this;
    this.$pWrap = $('.page-wrap');
    this.$map = $(this.mapSelector);
    this.$searchContainer = $(this.containerSelector);
    this.$searchResults = $(this.resultsSelector);
    this.$autoInput = $(this.autoInputSelector);
    this.$searchForm = $(this.searchFormSelector);
    this.$filterForm = $(this.filterFormSelector);
    this.$mapToggleBtn = $(this.mapToggleSelector);
    this.$mapCollapse = $(this.mapCollapseSelector);
    this.$fullListBtn = $(this.fullListSelector);
    this.$mapContainer = $(this.$mapToggleBtn.attr('data-target'));
    this.$stateFilter = $(this.stateFilterSelector);
    this.$userLocationLink = $(this.userLocationSelector);
    this.locationPath = $(window.locationSVG).find('path').attr('d');
    this.clusteredPath = $(window.clusteredSVG).find('path').attr('d');
    this.userPath = $(window.userSVG).find('path').attr('d');
    this.$sectionsWrap = this.$pWrap.find('.sections-wrap');

    this.activeLocations = $.extend(true, {}, window.storeLocStates);

    if (this.$sectionsWrap.data('lat') && this.$sectionsWrap.data('lat').length && this.$sectionsWrap.data('lng') && this.$sectionsWrap.data('lng').length) {
      this.mapCenter = {
        lat: Number(this.$sectionsWrap.data('lat')),
        lng: Number(this.$sectionsWrap.data('lng'))
      };
    }

    if (window.resultMapCenter) {
      this.userCenter = window.resultMapCenter;
    } else {
      if (!this.userCenter) {
        this.userCenter = this.mapCenter;
      }
    }

    if (this.$filterForm.length || this.$searchForm.length) {
      Parsley.on('form:submit', (formInstance) => {
        var formData = new FormData(formInstance.$element[0]);
        console.log("FormData: ", formData);
      });

      Parsley.on('form:validate', $.proxy(function(formInstance) {
        // formInstance.reset();
        // formInstance.refresh();
        var ok = formInstance.isValid();

        if (!ok) {
          formInstance.validationResult = false;
        }
      }, self));
    }

    if (this.$searchForm.length) {

      this.$searchForm.on('submit', $.proxy(this.onSearchSubmit, this));

      this.$userLocationLink.on('click', $.proxy(this.onUserLocationClick, this));

      this.$searchForm.parsley({
        inputs: Parsley.options.inputs + ',[data-parsley-check-children]',
      });
    }

    if (this.$filterForm.length) {

      this.$filterForm.on('change', this.stateFilterSelector, $.proxy(this.onStateFilterChange, this));

      this.$fullListBtn.on('click', $.proxy(this.onFullListClick, this));

      if (this.$mapToggleBtn.length) {
        this.$mapToggleBtn.on('click', $.proxy(this.onMapToggleBtn, this.$mapToggleBtn.get(0)));
      }
    }

    window.searchData = (window.searchData) ? window.searchData : {};
    this.geocoder = new google.maps.Geocoder();
    this.initAutoComplete();

    this.bounds = new google.maps.LatLngBounds();

    this.initSearchMap();

    return this;
  },
  initSearchMap() {

    // $.subscribe("oms.ready", $.proxy(function() {
      if (this.$map.length) {
        this.$map.gmap3({
          center: this.mapCenter,
          zoom: 12,
          minZoom: 4,
          maxZoom: 17,
          streetViewControl: false,
          gestureHandling: 'cooperative',
          mapTypeControl: false,
          mapTypeId: 'roadmap',
          scrollwheel: false,
          clickableIcons: false,
          styles: this.mapStyle
        }).then((map) => {
          this.theMap = (map.map) ? map.map : map;
          window.theMap = this.theMap;
          google.maps.event.addListenerOnce(this.theMap, 'idle', $.proxy(function() {
            this.geocodeLocation(this.mapCenter, $.proxy(function(result) {
              // console.log('Location Geocoded', result);
              this.populateResults(this.activeLocations);
            }, this), -1).bindResultIconLinks().onMapReady();
          }, this));
          return this.theMap;
        });
      }
    // }, this));
    if (!this.$map.length) {
      this.geocodeLocation(this.mapCenter, $.proxy(function(result) {
        // console.log('Location Geocoded', result);
        this.populateResults(this.activeLocations);
      }, this), -1);
    }

    return this;

  },
  onUserLocationClick(e) {
    e.preventDefault();
    this.$userLocationLink.addClass('loading');
    this.getUserLocation();

    return;
  },
  onMapToggleBtn(e) {
    let $this = $(this);
    const state = ($this.attr('aria-expanded') === 'true');
    if (state) {
      $this.text('Show Map');
    } else {
      $this.text('Hide Map');
    }
    $this.attr('aria-expanded', !state.toString());

    return this;
  },
  onFullListClick(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    $('.store-list-heading').text('Store Locations');
    this.activeState = false;
    this.restrictResults = false;
    this.activeLocations = $.extend(true, {}, window.storeLocStates);
    this.setStateOverlayStyles();
    this.refreshSearchResults(this.activeLocations);
    this.$stateFilter.val("");
    this.$searchResults.find('.state-container').show();
    if (this.$map.length) {
      this.scrollToMap();
    } else {
      this.scrollToResults();
    }

    return this;
  },
  scrollToMap(callback) {
    const offset = this.$pWrap.find('.page-section.hero').outerHeight(true);
    gsap.to(this.$pWrap, {scrollTo: offset, duration: 1, ease: "expo.out", onComplete: function() {
      if (callback && typeof callback === "function") {
        callback.call();
      }
    }});

    return this;
  },
  scrollToResults(callback) {
    const offset = this.$pWrap.find('.page-section.hero').outerHeight(true) + this.$pWrap.find('.page-section.search-locations-map-section').outerHeight(true);
    gsap.to(this.$pWrap, {scrollTo: offset, duration: 1, ease: "expo.out", onComplete: function() {
      if (callback && typeof callback === "function") {
        callback.call();
      }
    }});
    return this;
  },
  onStateFilterChange(e) {
    e.preventDefault();
    var $this = $(e.currentTarget);
    const $selected = $this.find('option:selected');
    const val = $selected.attr('data-state');
    if ($selected.hasClass('retail')) {
      this.restrictResults = 'retail';
    } else if ($selected.hasClass('wholesale')) {
      this.restrictResults = 'wholesale';
    } else {
      this.restrictResults = false;
    }
    if (val && val.length) {
      this.activeState = val;
      // this.removeStateOverlays();
      this.setStateOverlayStyles();
      this.filterLocationsByState();
    } else {
      this.activeState = false;
      this.activeLocations = $.extend(true, {}, window.storeLocStates);
    }
    if (this.$map.length) {
      this.scrollToMap($.proxy(function() {
        this.refreshSearchResults(this.activeLocations);
      }, this));
    } else {
      this.scrollToResults($.proxy(function() {
        this.refreshSearchResults(this.activeLocations);
      }, this));
    }

    return this;
  },
  onSearchSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    // var formData = new FormData(this.$searchForm.get(0));
    // console.log('FormData', formData);
    // this.$stateFilter.attr('disabled', false);
    // this.$fullListBtn.attr('disabled', false);
    const $lat = this.$searchForm.find('.form-control.Latitude');
    const $lng = this.$searchForm.find('.form-control.Longitude');
    this.mapCenter = window.resultMapCenter = {lat: Number($lat.val()), lng: Number($lng.val())};
    if (this.$map.length) {
      this.theMap.setCenter(this.mapCenter);
    }

    var $form = $(e.currentTarget);
    var $radius = $form.find('select.Radius');
    window.searchData.radius = Number($radius.val());

    var circleObj = {
      center: this.mapCenter,
      radius: Math.ceil(metersInMile * window.searchData.radius),
      strokeColor: secondary,
      strokeOpacity: 0.5,
      strokeWeight: 2,
      fillColor: secondaryHover,
      fillOpacity: 0.2,
    };

    if (this.circle && this.circle.setMap) {
      this.circle.setMap(null);
    }

    this.circle = new google.maps.Circle(circleObj);
    this.bounds = this.circle.getBounds();

    var allLocations = [];
    this.activeLocations = {};

    Object.keys(window.storeLocStates).map($.proxy((state) => {
      var retail = window.storeLocStates[state].locations.retail;
      var wholesale = window.storeLocStates[state].locations.wholesale;
      allLocations = allLocations.concat(retail, wholesale);
      this.activeLocations[state] = {
        abbr: state,
        locations: {
          retail: [],
          wholesale: []
        },
        name: window.statesObj[state]
      };
    }, this));

    allLocations.map($.proxy(function (loc) {
      if (this.bounds.contains(loc.pos)) {
        this.activeLocations[loc.state].locations[loc.type.toLowerCase()].push(loc);
      }
    }, this));

    $('.store-list-heading').text('Search Results');

    this.$stateFilter.val("");
    this.resetMap().populateResults(this.activeLocations);

    if (this.$map.length) {
      this.circle.setMap(this.theMap);
      this.theMap.fitBounds(this.bounds);

      this.scrollToMap($.proxy(function() {
        // this.theMap.setZoom(8);
        this.setUserMarker();
      }, this));
    } else {
      this.scrollToResults($.proxy(function() {
        // this.theMap.setZoom(8);
        this.setUserMarker();
      }, this));
    }

    return this;
  },
  bindResultIconLinks() {
    this.$pWrap.on('click', this.storeListLinkSelector, $.proxy(function(e) {
      e.preventDefault();
      var $this = $(e.currentTarget);
      var index = Number($this.attr('data-index'));
      var id = Number($this.attr('data-id'));
      // google.maps.event.trigger(this.allMarkers[id], 'spider_click');
      google.maps.event.trigger(this.allMarkers[id], 'click');
      if (!this.$mapContainer.hasClass('show')) {
        this.$mapContainer.one('shown.bs.collapse', $.proxy(function(e) {
          if (this.$map.length) {
            this.scrollToMap();
          } else {
            this.scrollToResults();
          }
        }, this));
        this.$mapToggleBtn.click();
      } else {
        if (this.$map.length) {
          this.scrollToMap();
        } else {
          this.scrollToResults();
        }
      }
    }, this));

    return this;
  },
  filterLocationsByBounds(bounds) {
    return this;
  },
  filterLocationsByState() {
    const state = this.activeState;
    if (state) {
      this.bounds = new google.maps.LatLngBounds();
      $('.store-list-heading').text('Store Locations');
      this.activeLocations = {};
      this.activeLocations[state] = $.extend(true, {}, window.storeLocStates[state]);
      this.$searchResults.find('.state-' + state).siblings().hide();
      if (this.restrictResults && this.restrictResults.length) {
        this.$searchResults.find('.state-' + state).not('[data-type="' + this.restrictResults + '"]').hide();
        this.$searchResults.find('.state-' + state).filter('[data-type="' + this.restrictResults + '"]').show();
      } else {
        this.$searchResults.find('.state-' + state).not('.empty').show();
      }
    } else {
      console.log('Cannot filter by state. "this.activeState" is not a valid state name.');
    }

    return this;
  },
  addStateOverlays() {
    if (this.$map.length) {
      var self = this;
      Object.keys(this.stateBorders).map((key) => {
        const borders = self.stateBorders[key];
        let bounds = self.stateBounds[key];
        const coords = borders.features[0].geometry.coordinates;
        if (coords.length > 1) {
          coords.map((co) => {
            co[0].map((coord) => {
              const pos = {lat: coord[1], lng: coord[0]};
              bounds.extend(pos);
            });
          });
        } else {
          coords[0].map((coord) => {
            const pos = {lat: coord[1], lng: coord[0]};
            bounds.extend(pos);
          });
        }
        const borderStr = JSON.stringify(borders);
        self.dataLayers.push(self.theMap.data.addGeoJson(JSON.parse(borderStr), {
          idPropertyName: 'STUSPS'
        }));
      });
    }

    return this;
  },
  removeStateOverlays() {
    if (this.$map.length) {
      this.theMap.data.forEach($.proxy(function(feature) {
        var fID = feature.getId();
        if (fID !== this.activeState) {
          this.theMap.data.remove(feature);
        }
      }, this));
    }

    return this;
  },
  setStateOverlayStyles() {
    if (this.$map.length) {
      this.theMap.data.forEach($.proxy(function(feature) {
        this.theMap.data.revertStyle(feature);

        if(this.activeState) {
           var fID = feature.getId();
           if (fID !== this.activeState) {
             this.theMap.data.overrideStyle(feature, Object.assign({}, this.defStateOverlayStyles, {
               fillColor: 'rgba(255,255,255,0)',
               strokeColor: 'rgba(255,255,255,0)',
             }));
           }
        }
       }, this));
    }

    return this;
  },
  initAutoComplete() {
    // Create the autocomplete object, restricting the search to geographical
    // location types.
    let aComplete = new google.maps.places.Autocomplete(this.$autoInput[0], {
      bounds: this.bounds,
      strictBounds: false,
      types: ['address'],
    });

    aComplete.setFields(['address_components', 'geometry']);
    aComplete.addListener('place_changed', $.proxy(() => {
      this.fillInAddress(this.$autoInput, aComplete.getPlace());
    }, this));

    this.$autoInput.focus($.proxy((e) => {
      this.geoLocate(this.$autoInput);
    }, this));

    return this;
  },
  fillInAddress($elem, place) {
    // Get the place details from the autocomplete object.
    const $form = $elem.parents('form');
    const $address = $form.find('input.Address');
    const $radius = $form.find('select.Radius');
    const $city = $form.find('input.City');
    const $state = $form.find('input.State');
    const $zip = $form.find('input.Zip');
    const $lat = $form.find('input.Latitude');
    const $lng = $form.find('input.Longitude');
    const $userLat = $form.find('input.UserLat');
    const $userLng = $form.find('input.UserLng');
    let addVal = '';
    let cityVal = '';
    let stateVal = '';
    let abbrVal = '';
    let zipVal = '';
    const latVal = place.geometry.location.lat();
    const lngVal = place.geometry.location.lng();

    place.address_components.map((comp, index) => {
      if (comp.types.includes("street_number")) {
        addVal = comp.long_name + ' ';
      } else if(comp.types.includes("route")) {
        addVal += comp.long_name;
      } else if(comp.types.includes("locality")) {
        cityVal = comp.long_name;
      } else if(comp.types.includes("administrative_area_level_2") && (!cityVal || !cityVal.length)) {
        cityVal = comp.long_name;
      } else if(comp.types.includes("administrative_area_level_1")) {
        stateVal = comp.long_name;
        abbrVal = comp.short_name;
      } else if(comp.types.includes("postal_code")) {
        zipVal = comp.long_name;
      }
    });
    $address.val(addVal);
    $city.val(cityVal);
    $state.val(stateVal);
    $zip.val(zipVal);
    $lat.val(latVal);
    $lng.val(lngVal);
    $userLat.val(latVal);
    $userLng.val(lngVal);

    const isValid = $form.parsley().validate();

    if (isValid) {
      window.searchData.fullAddress = addVal + ' ' + cityVal + ', ' + stateVal + ' ' + zipVal;
      window.searchData.address = addVal;
      window.searchData.radius = Number($radius.val());
      window.searchData.state = stateVal;
      window.searchData.abbr = abbrVal;
      window.searchData.city = cityVal;
      window.searchData.zip = zipVal;
      window.searchData.lat = latVal;
      window.searchData.lng = lngVal;

      this.mapCenter = window.resultMapCenter = {lat: latVal, lng: lngVal};
    }

    return this;
  },
  onWinLoad() {
    return this;
  },
  geoLocate($elem) {
    // Bias the autocomplete object to the user's geographical location,
    // as supplied by the browser's 'navigator.geolocation' object.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition($.proxy(function(position) {
        var geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        var $form = $elem.parents('form');
        var $lat = $form.find('[name="Latitude"]');
        var $lng = $form.find('[name="Longitude"]');
        if ($lat.length) {
          $lat.val(geolocation.lat);
        }
        if ($lng.length) {
          $lng.val(geolocation.lng);
        }
        this.mapCenter = window.resultMapCenter = {lat: geolocation.lat, lng: geolocation.lng};
      }, this));
    }
    return this;
  },
  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition($.proxy(this.storePosition, this));
    }
    return this;
  },
  storePosition(position) {
    this.userCenter = {lat: position.coords.latitude, lng: position.coords.longitude};
    this.mapCenter = window.resultMapCenter = this.userCenter;
    this.geocodeLocation(this.userCenter, $.proxy((result) => {
      // console.log('User Location Geocoded!', result);
      this.$autoInput.val(result.formatted_address);
      this.$autoInput.focus();
    }, this), -1);
    // this.setLatLngUser();
    this.$userLocationLink.removeClass('loading');
    return this;
  },
  setLatLngUser() {
    var $lat = this.$searchForm.find('.form-control.Latitude');
    var $lng = this.$searchForm.find('.form-control.Longitude');
    $lat.val(this.userCenter.lat);
    $lng.val(this.userCenter.lng);
    return this;
  },
  resetMap() {
    var self = this;
    // this.oms.removeAllMarkers();
    this.removeAllMarkers();
    if (this.centerMarker && this.centerMarker.setMap) {
      this.centerMarker.setMap(null);
    }
    // this.dataLayers.map((layer) => {
    //   self.theMap.data.remove(layer);
    // });
    return this;

  },
  removeAllMarkers() {
    Object.keys(this.allMarkers).forEach($.proxy(function (key, index) {
      this.allMarkers[key].setMap(null);
    }, this));
    return this;
  },
  populateResults(locations) {

    this.$searchResults.find('.state-container > .states-row').empty();

    if (locations && Object.keys(locations).length) {
      this.bounds = new google.maps.LatLngBounds();
      Object.keys(locations).map((state, key) => {
        locations[state].locations.retail.sort((a, b) => {
          return mainJs.states[a.state].localeCompare(mainJs.states[b.state]);
        });
        locations[state].locations.wholesale.sort((a, b) => {
          return mainJs.states[a.state].localeCompare(mainJs.states[b.state]);
        });
      });
      Object.keys(locations).map((key) => {
        this.renderLocationsByState(locations[key]);
      });
      if (this.$map.length) {
        this.theMap.fitBounds(this.bounds);
        const curZoom = this.theMap.getZoom();
        if (curZoom > 10) {
          this.theMap.setZoom(10);
        }
      }
    } else {
      this.renderEmptyResults();
    }

    if (this.activeState) {
      if (this.restrictResults) {
        this.$searchResults.find('.state-' + this.activeState).not('.empty[data-type="' + this.restrictResults + '"]').hide();
        this.$searchResults.find('.state-' + this.activeState).filter('[data-type="' + this.restrictResults + '"]').show();
      } else {
        this.$searchResults.find('.state-' + this.activeState).not('.empty').show();
      }
    }

    if (this.userMarkerSet) {
      this.setUserMarker();
    }

    return this;

  },
  renderEmptyResults() {

    this.$searchResults.find('.state-container > .states-row').empty();
    return this;
  },
  renderLocationsByState(state) {
    if (state.locations.retail.length) {
      this.$searchResults.find('.state-' + state.abbr).filter('[data-type="retail"]').show();
      state.locations.retail.map($.proxy((location, index) => {
        this.initStoreLocation(location, index);
        this.bounds.extend(location.pos);
      }, this));
    } else {
      this.$searchResults.find('.state-' + state.abbr).filter('[data-type="retail"]').hide();
    }
    if (state.locations.wholesale.length) {
      this.$searchResults.find('.state-' + state.abbr).filter('[data-type="wholesale"]').show();
      state.locations.wholesale.map($.proxy((location, index) => {
        this.initStoreLocation(location, index);
        this.bounds.extend(location.pos);
      }, this));
    } else {
      this.$searchResults.find('.state-' + state.abbr).filter('[data-type="wholesale"]').hide();
    }

    return this;
  },
  getFallbackIconSVG(color) {
    var svgSrc = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M14,31.24a2.91,2.91,0,0,0,3.93,0c.07-.06,7.16-6.69,7.93-7.45a13.88,13.88,0,0,0,0-19.7,14.05,14.05,0,0,0-19.8,0,13.88,13.88,0,0,0,0,19.7C6.86,24.53,14,31.18,14,31.24Zm-4.39-11a8.91,8.91,0,0,1,0-12.63,9,9,0,0,1,12.7,0,8.91,8.91,0,0,1,0,12.63c-.73.72-5.83,5.5-5.83,5.5a.76.76,0,0,1-1,0S10.37,21,9.65,20.24ZM16,17.5a3.57,3.57,0,1,0-3.59-3.57A3.58,3.58,0,0,0,16,17.5Z" style="fill: ` + (color || primary) + `"/></svg>`;
    return svgSrc;
  },
  initStoreLocation(store, index) {

    if (store) {
        this.renderStoreLocation(store, index);
        if (!this.restrictResults || (this.restrictResults && this.restrictResults.length && (store.type.toLowerCase() === this.restrictResults))) {
          var $iconElem = $('<div class="store-icon-wrap"></div>');
          $iconElem.html(window.locationSVG);
          this.setStoreMarker(store, index, this.locationPath);
        }
    }


    return this;
  },
  renderStoreLocation(store, index) {

    var iconURL = window.locationMapIcon.replace(window.location.origin, '');
    var $iconElem = $(`<div class="store-icon-wrap"><img class="store-icon-img" src="` + iconURL + `" alt="` + store.title + `" /></div>`);

    if (iconURL.match('.svg')) {
      LoadFile(iconURL, 'svg', $.proxy((response) => {
        $iconElem = $('<div class="store-icon-wrap"></div>').html(response);
        var $svg = $iconElem.find('svg');
        $svg.addClass('store-icon-img').attr('id', '');

        $iconElem.find('path').addClass('marker-icon-path');
        var storeStr = this.getStoreStr(store, index, $iconElem);
        this.$searchResults.find('.state-' + store.state).filter('[data-type="' + store.type.toLowerCase() + '"]').find(' > .states-row').append(storeStr);
      }, this));
    } else {
      var storeStr = this.getStoreStr(store, index, $iconElem);
      this.$searchResults.find('.state-' + store.state).filter('[data-type="' + store.type.toLowerCase() + '"]').find(' > .states-row').append(storeStr);
    }
    return this;
  },
  getStoreStr(store, index, $iconElem) {

    var svgSrc = `<svg xmlns="//www.w3.org/2000/svg" viewBox="0 0 39 44.8">
      <defs>
        <style>
          .location-icon-path {
            fill: #f5c65d;
            fill-rule: evenodd;
          }
          .location-icon-path:hover,
          .location-icon-path:active {
            fill: #f4be45;
          }
        </style>
      </defs>
      <g id="location-icon-group-2" data-name="Layer 2">
        <g id="location-icon-group-1" data-name="Layer 1">
          <path class="location-icon-path"
          d="M16.76,43.74a4,4,0,0,0,5.48,0c.1-.09,10-9.38,11.05-10.44a19.5,19.5,0,1,0-27.58,0C6.76,34.34,16.67,43.65,16.76,43.74Zm-6.1-15.4a12.5,12.5,0,1,1,17.68,0c-1,1-8.11,7.7-8.11,7.7a1.07,1.07,0,0,1-1.46,0S11.65,29.33,10.66,28.34ZM19.5,24.5a5,5,0,1,0-5-5A5,5,0,0,0,19.5,24.5Z"/>
        </g>
      </g>
    </svg>`;

    var directionsURL = `https://www.google.com/maps/dir/` + window.searchData.fullAddress.replace(' ', '+') + `,+USA/` + store.location.address.replace(' ', '+') + `,+USA`;
    var rawPhone = store.phone.replace(/[^0-9]/g, '');
    var titleURL = (this.$map.length) ? '#' : directionsURL;
    var linksTitle = (this.$map.length) ? 'View on Map' : 'Get Directions';

    var templateStr = `<div class="col-12 col-md-6 store-location-item" data-state="` +  mainJs.states[store.state] + `" data-index="` + index + `"  data-id="` + store.ID + `">
    <div class="store-location-inner py-3 px-4 d-flex justify-content-start align-items-start" data-lat="` + store.pos.lat + `" data-lng="` + store.pos.lng + `" data-id="` + store.ID + `">
      <div class="store-icon mr-4"><a rel="noopener" href="` + titleURL + `" title="` + linksTitle + `" target="_blank" class="store-list-icon-link d-block" data-index="` + index + `" data-id="` + store.ID + `">` + svgSrc + `</a></div>
      <div class="store-details">
        <h4 class="item-title"><a rel="noopener" href="` + titleURL + `" title="` + linksTitle + `" target="_blank" class="store-list-icon-link d-block text-decoration-none" data-index="` + index + `" data-id="` + store.ID + `">` + store.title + `</a></h4>
        <p class="full_address mb-0">
          <a rel="noopener" href="` + directionsURL + `" target="_blank" title="Get Directions">
            <span class="address">` + store.address + `</span>`;
            if(store.address2 && store.address2.length) {
              templateStr+= `<span class="address2">` + store.address2 + `</span>`;
            }
            if(store.city && store.city.length) {
              templateStr+= `<br/><span class="city">` + store.city + `</span>,`;
            }
            if(store.state && store.state.length) {
              templateStr+= ` <span class="state">` + store.state + `</span>`;
            }
            if(store.zip && store.zip.length) {
              templateStr+= ` <span class="zip">` + store.zip + `</span>`;
            }
            templateStr+= `</a></p>`;

        if(store.phone && store.phone.length) {
          templateStr+= `<p class="phone mb-0">
            <a href="tel:` + rawPhone + `">` + store.phone + ((store.ext && store.ext.length) ? ' ' + store.ext : '') + `</a>
          </p>`;
        }
        if(store.cell && store.cell.length) {
          var rawCell = store.cell.replace(/[^0-9]/g, '');
          templateStr+= `<p class="cell mb-0">
            <a href="tel:` + rawCell + `">` + store.cell + `</a> (cell)
          </p>`;
        }
        if(store.fax && store.fax.length) {
          var rawFax = store.fax.replace(/[^0-9]/g, '');
          templateStr+= `<p class="fax mb-0">
            <a href="tel:` + rawFax + `">` + store.fax + `</a> (fax)
          </p>`;
        }
        if(store.email && store.email.length) {
          templateStr+= `<p class="fax mb-0">
            <a href="mailto:` + store.email + `">` + store.email + `</a>
          </p>`;
        }
        if(store.web && store.web.length) {
          templateStr+= `<p class="web mb-0">
            <a href="` + store.web + `" target="_blank">` + store.web + `</a>
          </p>`;
        }
        templateStr+= `</div>
        </div>
      </div>`;

    return templateStr;
  },
  refreshSearchResults(locations) {

    if (this.circle) {
      this.circle.setMap(null);
    }
    if (locations) {
      this.resetMap().populateResults(locations);
    }

    return this;
  },
  geocodeAddress(address, callback, index) {
    var self = this;
    this.geocoder.geocode({'address': address}, $.proxy(function(results, status) {
      if (status === 'OK') {
        var result = results[0];
        const locationObj = {
          address: result.address_components[0].long_name + ' ' + result.address_components[1].long_name,
          addressFormatted: result.formatted_address,
          location: {
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng()
          },
          city: result.address_components[3].long_name,
          state: result.address_components[5].long_name,
          zip: result.address_components[7].long_name
        };
        if (index >= 0) {
          window.stores['index' + index] = locationObj;
        } else {
          window.resultMapCenter = locationObj.location;
        }
        if (typeof callback == 'function') {
          callback.call(self);
        }
      } else {
        alert(mainJs.geocode_fail + ': ' + status);
      }
    }, this));
  },
  geocodeLocation(location, callback, index) {
    var self = this;
    this.geocoder.geocode({'location': location}, $.proxy(function(results, status) {
      if (status === 'OK') {
        const result = results[0];
        let locationObj = {};
        if (result.address_components.length == 7) {
          locationObj = {
            address: result.address_components[0].long_name + ' ' + result.address_components[1].long_name,
            addressFormatted: result.formatted_address,
            location: location,
            city: result.address_components[2].long_name,
            state: result.address_components[4].long_name,
            abbr: result.address_components[4].short_name,
            zip: result.address_components[6].long_name
          };
        } else if (result.address_components.length == 8) {
          locationObj = {
            address: result.address_components[0].long_name + ' ' + result.address_components[1].long_name,
            addressFormatted: result.formatted_address,
            location: location,
            city: result.address_components[3].long_name,
            state: result.address_components[5].long_name,
            abbr: result.address_components[5].short_name,
            zip: result.address_components[7].long_name
          };
        }
        if (index >= 0) {
          window.stores['index' + index] = locationObj;
        } else {
          window.resultMapCenter = location;
          window.searchData.fullAddress = locationObj.address + ' ' + locationObj.city + ', ' + locationObj.state + ' ' + locationObj.zip;
          window.searchData.address = locationObj.address;
          window.searchData.state = locationObj.state;
          window.searchData.abbr = locationObj.abbr;
          window.searchData.city = locationObj.city;
          window.searchData.zip = locationObj.zip;
          window.searchData.lat = location.lat;
          window.searchData.lng = location.lng;
        }
        if (typeof callback == 'function') {
          callback.call(self, result);
        }
      } else {
        alert(mainJs.geocode_fail + ': ' + status);
      }
    }, this));
    return this;
  },
  setupLoader(isLoading, callback) {
    this.isLoading = isLoading;

    if (this.isLoading) {
      this.$searchContainer.addClass('loading');
      setTimeout($.proxy(function() {
        this.loaderTL.play();
        gsap.fromTo(this.$containerLoader, {autoAlpha: 0}, {autoAlpha: 1, duration: 1, ease: "expo.out", onComplete: $.proxy(() => {
          if (callback && typeof callback === 'function') {
            callback();
          }
        }, this)});
      }, this), 1);
    } else {
      gsap.fromTo(this.$containerLoader, {autoAlpha: 1}, {autoAlpha: 0, duration: 1, ease: "expo.out", onComplete: $.proxy(() => {
        this.$searchContainer.removeClass('loading');
        this.loaderTL.pause();
        if (callback && typeof callback === 'function') {
          callback();
        }
      }, this)});
    }
    return this;
  },
  setUserMarker() {

    if (this.$map.length) {
      var userIcon = {
        path: this.userPath,
        fillColor: secondary,
        fillOpacity: 1.0,
        strokeWeight: 1,
        strokeColor: secondaryHover,
        strokeOpacity: 1,
        scale: 1.2,
        labelOrigin: this.labelOrigin,
        anchor: this.iconAnchor,
        size: this.iconSize,
        scaledSize: this.iconSize
      };

      var cMarkerObj = {
        position: this.mapCenter,
        icon: userIcon,
        label: null,
        info: this.iw,
        map: this.theMap,
        index: 0,
        indexText: 0,
        title: 'Search Location',
        zIndex: 1000,
        optimized: false,
        details: {
          id: 1000,
        },
        animation: google.maps.Animation.DROP,
      };
      if (this.centerMarker && this.centerMarker.setMap) {
        this.centerMarker.setMap(null);
      }
      this.centerMarker = new google.maps.Marker(cMarkerObj);

      google.maps.event.addListener(this.centerMarker, 'click', $.proxy(function() {
        this.onCenterMarkerClick(this.centerMarker);
      }, this));

      google.maps.event.addListener(this.centerMarker, 'mouseover', $.proxy(function() {
        let icon = $.extend(true, {}, this.centerMarker.getIcon());
        var hoverObj = {
          scale: icon.scale,
          fillColor: secondaryHover,
        };

        this.centerMarker.setZIndex(10000);

        gsap.fromTo(hoverObj, {
          scale: icon.scale,
          fillColor: secondary,
        },
        {
          scale: 1.6,
          duration: 0.15,
          fillColor: secondaryHover,
          ease: "expo.out",
          onUpdate: $.proxy(function() {
            const newIcon = $.extend(true, icon, {
              scale: hoverObj.scale,
              fillColor: hoverObj.fillColor,
            });
            this.centerMarker.setIcon(newIcon);
          }, this),
        });
      }, this));

      google.maps.event.addListener(this.centerMarker, 'mouseout', $.proxy(function() {
        let icon = this.centerMarker.getIcon();
        var hoverObj = {
          scale: icon.scale,
          fillColor: secondaryHover,
        };

        gsap.fromTo(hoverObj, {
          scale: hoverObj.scale,
          fillColor: hoverObj.fillColor,
        },
        {
          scale: 1.2,
          duration: 0.15,
          fillColor: secondary,
          ease: "expo.out",
          onUpdate: $.proxy(function() {
            const newIcon = $.extend(true, icon, {
              scale: hoverObj.scale,
              fillColor: hoverObj.fillColor,
            });
            this.centerMarker.setIcon(newIcon);
          }, this),
          onComplete: $.proxy(function() {
            this.centerMarker.setZIndex(10000);
          }, this),
        });
      }, this));

      if (!this.userMarkerSet) {
        this.userMarkerSet = true;
      }
      this.allMarkers['center'] = this.centerMarker;
      // this.theMap.setCenter(this.centerMarker.position);
    }

    return this;
  },
  setStoreMarker(store, index, svgPath) {
    var lat;
    var lng;
    var markerPos;
    var markerIcon;

    lat = store.pos.lat;
    lng = store.pos.lng;

    markerIcon = {
      // url: window.locationMapIcon,
      path: svgPath,
      fillColor: primary,
      fillOpacity: 1.0,
      strokeColor: primaryHover,
      strokeOpacity: 1,
      strokeWeight: 1,
      scale: 1.2,
      labelOrigin: this.labelOrigin,
      anchor: this.iconAnchor,
      size: this.iconSize,
      scaledSize: this.iconSize
    };

    markerPos = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
    // this.bounds.extend(markerPos);

    var markerLabel = {
      color: '#fff',
      // text: (index + 1).toString(),
      text: ' ',
      fontSize: '13px',
    };

    var mObj = {
      position: markerPos,
      label: markerLabel,
      icon: markerIcon,
      info: this.iw,
      map: this.theMap,
      id: store.ID,
      index: index,
      indexText: index + 1,
      title: store.title,
      details: store,
      optimized: false,
      animation: google.maps.Animation.DROP,
      zIndex: ((index + 1) * 10),
      opacity: 1,
      visible: true,
      draggable: false,
      cursor: 'pointer',
      crossOnDrag: false,
      clickable: true,
      lastStatus: false,
      status: false,
    };

    var sMarker = new google.maps.Marker(mObj);
    // this.oms.addMarker(sMarker);
    new google.maps.Marker(sMarker);

    if (this.$map.length) {

      // google.maps.event.addListener(sMarker, 'spider_click', $.proxy(function(e) {
      //   console.log(sMarker.details.ID, sMarker.status, "Spider marker click");
      //   var content = this.getIWContent(this.allStores[sMarker.id]);
      //   this.closeIW();
      //   this.iw.setContent(content);
      //   this.iw.open(this.theMap, sMarker);
      //   var $iw = $(".infowindow");
      //   $iw.parent().css("overflow", "hidden");
      // }, this));

      google.maps.event.addListener(sMarker, 'mouseover', () => {
        var icon = sMarker.getIcon();
        let label = sMarker.getLabel();
        var iconColor = (sMarker.status == "SPIDERFIABLE") ? tertiary : primary;
        var iconColorHover = (sMarker.status == "SPIDERFIABLE") ? tertiaryHover : primaryHover;
        var hoverObj = {
          scale: icon.scale,
          fontSize: 13,
          fillColor: iconColor
        };

        sMarker.setZIndex(10000);

        gsap.fromTo(hoverObj, {
          scale: hoverObj.scale,
          fontSize: hoverObj.fontSize,
          fillColor: hoverObj.fillColor,
        },
        {
          scale: 1.6,
          duration: 0.15,
          fontSize: 16,
          fillColor: iconColorHover,
          ease: "expo.out",
          onUpdate: $.proxy(function() {
            sMarker.setIcon($.extend(true, icon, {scale: hoverObj.scale, fillColor: hoverObj.fillColor}));
            sMarker.setLabel($.extend(true, label, {fontSize: hoverObj.fontSize + 'px'}));
          }, this),
        });
      });

      google.maps.event.addListener(sMarker, 'mouseout', () => {
        var icon = sMarker.getIcon();
        let label = sMarker.getLabel();
        var iconColor = (sMarker.status == "SPIDERFIABLE") ? tertiary : primary;
        var iconColorHover = (sMarker.status == "SPIDERFIABLE") ? tertiaryHover : primaryHover;
        var hoverObj = {scale: icon.scale, fontSize: 13, fillColor: iconColorHover};

        gsap.fromTo(hoverObj, {
          scale: hoverObj.scale,
          fontSize: hoverObj.fontSize,
          fillColor: hoverObj.fillColor,
        },
        {
          scale: 1.2,
          duration: 0.15,
          fontSize: 13,
          fillColor: iconColor,
          ease: "expo.out",
          onUpdate: $.proxy(function() {
            sMarker.setIcon($.extend(true, icon, {scale: hoverObj.scale, fillColor: hoverObj.fillColor}));
            sMarker.setLabel($.extend(true, label, {
              fontSize: hoverObj.fontSize + 'px',
            }));
          }, this),
          onComplete: $.proxy(function() {
            sMarker.setZIndex(sMarker.index * 10);
          }, this),
        });
      });

      google.maps.event.addListener(sMarker, 'click', $.proxy(function(e) {
        // e.stop();
        console.log(sMarker.details.ID, sMarker.status, "Regular marker click");
        var content = this.getIWContent(this.allStores[sMarker.id]);
        var iwMap = this.iw.getMap();
        if (iwMap !== null && typeof iwMap !== "undefined" && content === this.iw.content) {
          // this.closeIW();
        } else {
          // this.closeIW();
          this.iw.setContent(content);
          this.iw.open(this.theMap, sMarker);
          var $iw = $(".infowindow");
          $iw.parent().css("overflow", "hidden");
        }
      }, this));

      google.maps.event.addListener(this.iw, 'closeclick', $.proxy(function(e) {
        if (this.iw.getMap() && this.iw.getContent()) {
            this.closeIW();
        }
        return false;
      }, this));

      // google.maps.event.addListener(sMarker, 'spider_format', $.proxy(function(status) {
      //   var markersNear = this.oms.markersNearMarker(sMarker);
      //   if (markersNear.length && status === "SPIDERFIABLE") {
      //     this.setMarkerLabel(sMarker, (markersNear.length + 1).toString());
      //   } else {
      //     this.setMarkerLabel(sMarker, ' ');
      //   }
      // }, this));

      // oms.addListener('format', function(marker, status) {
      //   var iconURL = status == this.oms.markerStatus.SPIDERFIED ? 'marker-highlight.svg' :
      //     status == this.oms.markerStatus.SPIDERFIABLE ? 'marker-plus.svg' :
      //     status == this.oms.markerStatus.UNSPIDERFIABLE ? 'marker.svg' :
      //     null;
      //   marker.setIcon({
      //     url: iconURL,
      //     scaledSize: new google.maps.Size(23, 32);  // makes SVG icons work in IE
      //   });
      // });

      // var newLatLng = new google.maps.LatLng(markerPos.lat, markerPos.lng);

    }

    if (typeof this.allMarkers !== 'object') {
      this.allMarkers = {};
    }
    this.allMarkers[store.ID] = sMarker;

    if (store) {
      if (!$.isArray(this.allStores)) {
        this.allStores = [];
      }
      this.allStores[store.ID] = $.extend(true, {
        title: store.title,
        id: store.ID,
        details: store,
        index: index,
      }, mObj);
    }

    return this;
  },
  setMarkerColors(marker, mColors) {
    var icon = marker.getIcon();
    var colors = (mColors || this.getSrcDestColors(marker.lastStatus, marker.status));
    // var colorObj = {fillColor: icon.fillColor};
    var colorObj = {fillColor: colors.dest};

    // gsap.fromTo(colorObj, {fillColor: colors.src}, {fillColor: colors.dest, duration: 0.15, ease: "expo.out", onUpdate: $.proxy(function() {
      marker.setIcon($.extend(true, icon, colorObj));
    // }, this)});
    return this;
  },
  // setMarkerLabel(marker, labelText) {
  //   var nearCount = this.oms.markersNearMarker(marker);
  //   var text = (labelText || (marker.status == "SPIDERFIABLE" && nearCount && nearCount.length) ? (nearCount.length + 1).toString() : ' ');

  //   marker.setLabel({
  //     text: text,
  //     color: '#fff',
  //     fontSize: '13px',
  //   });
  //   return this;
  // },
  getSrcDestColors(oldStatus, newStatus) {
    var srcColor = primary;
    var destColor = tertiary; // statusTypes.SPIDERFIABLE

    var statusTypes = OverlappingMarkerSpiderfier.markerStatus;
    srcColor = (oldStatus == statusTypes.SPIDERFIABLE) ? tertiary : primary;
    destColor = (newStatus == statusTypes.SPIDERFIABLE) ? tertiary : primary;
    // if (newStatus == statusTypes.SPIDERFIED) {
    //   destColor = primary;
    //   if (oldStatus == statusTypes.UNSPIDERFIED || oldStatus == statusTypes.SPIDERFIABLE) {
    //     srcColor = tertiary;
    //   } else if (oldStatus == statusTypes.UNSPIDERFIABLE || oldStatus == statusTypes.SPIDERFIED) {
    //     srcColor = primary;
    //   }
    // } else if (newStatus == statusTypes.SPIDERFIABLE) {
    //   destColor = tertiary;
    //   if (oldStatus == statusTypes.UNSPIDERFIED || oldStatus == statusTypes.UNSPIDERFIABLE) {
    //     srcColor = primary;
    //   } else if (oldStatus == statusTypes.SPIDERFIABLE) {
    //     srcColor = tertiary;
    //   } else if (oldStatus == statusTypes.SPIDERFIED) {
    //     srcColor = primary;
    //   }
    // } else if (newStatus == statusTypes.UNSPIDERFIED || newStatus == statusTypes.UNSPIDERFIABLE) {
    //   destColor = tertiary;
    //   if (oldStatus == statusTypes.UNSPIDERFIED || oldStatus == statusTypes.UNSPIDERFIABLE) {
    //     srcColor = primary;
    //   } else if (oldStatus == statusTypes.SPIDERFIABLE) {
    //     srcColor = tertiary;
    //   } else if (oldStatus == statusTypes.SPIDERFIED) {
    //     srcColor = primary;
    //   }
    // }

    return {
      src: srcColor,
      dest: destColor,
    };
  },
  // onMapMarkerFormat(marker, status) {
  //   var markersNear = this.oms.markersNearMarker(marker);
  //   // console.log("Formatting marker", marker.indexText, marker.status, status);
  //   if (!marker.status || marker.status !== status) {
  //     var icon = marker.getIcon();

  //     // var svg = (markersNear.length && marker.status != "SPIDERFIABLE") ? window.clusteredSVG : window.locationSVG;
  //     var svgPath = (status == "SPIDERFIABLE") ? this.clusteredPath : this.locationPath;
  //     var markerIcon = $.extend(true, icon, {
  //       // url: window.locationSVG,
  //       path: svgPath,
  //       fillOpacity: 1.0,
  //       scale: 1.2,
  //       labelOrigin: this.labelOrigin,
  //       anchor: this.iconAnchor,
  //       fillColor: status == "SPIDERFIABLE" ? tertiary : primary,
  //       strokeColor: status == "SPIDERFIABLE" ? tertiaryHover : primaryHover,
  //     });

  //     // var colors = this.getSrcDestColors(marker.status, status);
  //     // var colorObj = { fillColor: icon.fillColor };
  //     // var colorObj = { fillColor: colors.dest };
  //     // markerIcon.fillColor = colors.dest;
  //     // gsap.fromTo(colorObj, {fillColor: colors.src}, {fillColor: colors.dest, duration: 0.15, ease: "expo.out", onUpdate: $.proxy(function() {
  //       // marker.setIcon($.extend(true, markerIcon, colorObj));
  //       marker.setIcon(markerIcon);
  //     // }, this)});
  //   }

  //   marker.status = status;
  //   marker.lastStatus = marker.status;
  //   this.setMarkerLabel(marker);

  //   return this;
  // },
  onCenterMarkerClick(marker) {
    var content = `
    <div class="infowindow">
      <div class="container-fluid">
        <div class="row mx-0 text-left">
          <div class="col-12">
            <h6 class="h6 wt-300 mb-3 iw-site-title text-success">
              <span class="site-name d-block mb-1">` + marker.title + `</span>
            </h6>`;
          if (window.searchData.fullAddress) {
            content += `<p class="wt-300 mb-2 body">` + window.searchData.fullAddress + `</p>`;
          }
          content +=
            `<div class="d-flex flex-nowrap justify-content-between align-items-center">
              <p class="mb-0 mr-2">
                <span class="iw-lat-label">Latitude: </span>
                <span class="iw-lat">` + this.userCenter.lat + `</span>
              </p>
              <p class="mb-0 ml-2">
                <span class="iw-lng-label">Longitude: </span>
                <span class="iw-lng">` + this.userCenter.lng + `</span>
              </p>
            </div>
          </div>
          <div class="col-12 mt-3">
            <img class="info-logo" src="` + window.xcelLogoImage + `" alt="` + mainJs.siteTitle + `"/>
          </div>
        </div>
      </div>
    </div>`;
    this.iw.setContent(content);
    this.iw.open(this.theMap, marker);
    $(".infowindow").parent().css("overflow", "hidden");
  },
  changeStateOpacity() {
    if (this.$map.length) {
      const zoom = this.theMap.getZoom();
      const style = this.defStateOverlayStyles;
      let fOpacity;
      if (zoom >= 10) {
        fOpacity = 0.05;
      } else {
        fOpacity = 0.2;
      }

      if(this.activeState) {
        let feature = this.theMap.data.getFeatureById(this.activeState);
        this.theMap.data.overrideStyle(feature, $.extend({}, style, {fillOpacity: fOpacity}));
      } else {
        this.theMap.data.forEach($.proxy(function(feature) {
          this.theMap.data.revertStyle(feature);
        }, this));
        this.theMap.data.setStyle($.extend({}, style, {fillOpacity: fOpacity}));
      }
    }

    return this;
  },
  getIWContent(store) {
    var fullAddress = store.details.location.address;
    var directionsURL = `https://www.google.com/maps/dir/` + window.searchData.fullAddress.replace(' ', '+') + `,+USA/` + store.details.location.address.replace(' ', '+') + `,+USA`;
    var centerNameInner = `<span class="site-name d-block mb-1">` + store.details.title + `</span>`;
    var cStr = `
    <div class="infowindow">
      <div class="container-fluid">
        <div class="row mx-0 text-left">
          <div class="col-12">
            <h5 class="h5 wt-300 mb-3 iw-site-title">`;
              if(store.details.web && store.details.web.length) {
                cStr += `<a target="_blank" class="d-block iw-site-link" href="` + store.details.web + `">
                  ` + centerNameInner + `
                </a>`;
              } else {
                cStr += `<p class="iw-site-name">` + centerNameInner + `</p>`;
              }
            cStr += `</h5>
            <address class="mb-2 d-flex flex-nowrap justify-content-start align-items-start">
              <i class="fa fa-map-marker mr-1"></i> <a rel="noopener" href="` + directionsURL + `" target="_blank" class="address-link text-success d-inline-block" title="Get Directions"><span class="text">` + fullAddress + `</span></a>
            </address>
            <div class="phone-wrap">
              <i class="fa fa-phone mr-1"></i> <a class="phone-link mb-2 d-inline-block text-success" href="tel:` + store.details.phone.replace(/[^0-9]/g, '') + `"><span class="text">` + store.details.phone + `</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>`;
    return cStr;
  },
  closeIW() {
    this.iw.close();
    return this;
  }
}
