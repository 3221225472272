import 'jquery';

var playersInfo = [];
var playersSetup = false;
var playersReady = false;

var iframes = document.getElementsByClassName("youtube-video");

function loadYTScript(type = "iframe") {
  var tag = document.createElement('script');
  tag.id = 'yt-iframe-api';
  tag.src = "//www.youtube.com/iframe_api";
  if(type == "player") {
    tag.src = "//www.youtube.com/player_api";
  }
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

function gatherYTFrames() {
  for (var i = 0; i < iframes.length; i++) {
    var frame = iframes[i];
    var id = frame.getAttribute('id');
    var vidID = frame.getAttribute('data-id');
    playersInfo.push({
      id: id,
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: 0,
        loop: 1,
        controls: 0,
        showinfo: 0,
        modestbranding: 1,
        vq: 'hd1080',
      },
      videoId: vidID,
      events: {
        'onReady': onPlayerReady,
        'onStateChange': onPlayerStateChange,
      },
    });
  }
}

function onYouTubeIframeAPIReady() {
  setupPlayers();
}

function onYouTubePlayerAPIReady() {
  setupPlayers();
}

window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
window.onYouTubePlayerAPIReady = onYouTubePlayerAPIReady;

function setupPlayers() {
  if (!playersSetup) {
    playersReady = true;
    if (typeof playersInfo === 'undefined') return;

    playersInfo.forEach(function(info, i) {
      var curplayer = createPlayer(info);
      window.vidPlayers.youtube[info.id] = curplayer;
    });

    playersSetup = true;
  }
}

function onPlayerReady(event) {
  var player = event.target;
  var $iframe = $(player.getIframe());
  window.vidPlayers.youtube[$iframe.attr('id')] = player;
  if ($iframe.hasClass('autoplay')) {
    player.playVideo();
  }
  if ($iframe.hasClass('muted')) {
    player.mute();
  }
}

function onPlayerStateChange(event) {
  var player = event.target;
  var $elem = $(player.getIframe());
  var id = $elem.attr('id');
  if (event.data == 1) {
    Object.keys(window.vidPlayers.youtube).map(function(key) {
      var oPlayer = window.vidPlayers.youtube[key];
      var $topSec = $('.page-section:first-child');
      var $topYoutube = $topSec.find('.section-bg .youtube-video');
      if (id !== key && $topSec.hasClass('hero') && $topYoutube.length && id !== $topYoutube.attr('id') && oPlayer && oPlayer.pauseVideo) {
        oPlayer.pauseVideo();
      }
    });
  }
  // console.log('Player state changed!', $elem, event);
}

$.subscribe("slider.slideChanged", function(event, el) {
  var $el = $(el);
  var $youtube = $el.find('.youtube-video');
  $youtube.each(function() {
    var $vid = $(this);
    var id = $vid.attr('id');
    Object.keys(window.vidPlayers.youtube).map(function(key) {
      var oPlayer = window.vidPlayers.youtube[key];
      if (id === key && oPlayer && typeof oPlayer.pauseVideo == 'function') {
        oPlayer.pauseVideo();
      }
    });
  });
})

function createPlayer(playerInfo) {
  var id = playerInfo.id;
  // delete playerInfo.id;
  return new YT.Player(id, playerInfo);
}

export default {
  init() {
    loadYTScript();
    gatherYTFrames();
  },
}
