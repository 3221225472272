import 'jquery';
import 'bootstrap';
import Social from '../util/Social';
import VimeoApi from '../util/VimeoApi';
import YoutubeApi from '../util/YoutubeApi';
import Navigation from '../util/Navigation';
import AbsSlider from '../util/AbsSlider';
// import SliderSection from '../sections/sliderSection';
// import AccordionSection from '../sections/accordionSection';
import MapSection from '../sections/mapSection';
import TrainingLibSection from '../sections/trainingLibrarySection';
import StoreLocations from '../sections/storeLocationsSection';
import { gsap, TweenMax, TimelineMax, TweenLite, TimelineLite } from "gsap";
import ScrollToPlugin from "ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);


export default {
  scrollToSelector: '.scroll-to-link',
  sliders: [],
  init() {
    // JavaScript to be fired on all pages
    this.$w = $(window);
    this.$pWrap = $('.page-wrap');
    this.$scrollLinks = $(this.scrollToSelector);

    if (!$(document.body).hasClass('password-protected')) {
      $(document).ready($.proxy(this.onDocReady, this));
      // this.$w.on('load', $.proxy(this.onWinLoad, this));
    }
    return this;
  },
  onDocReady() {
    var self = this;
    const $secBGs = $(".page-section .section-bg, .site-container .section-bg");
    const $contentBoxes = $('.page-section .box-content, body.single .post-content');
    const $courseListForm = $('form.courses-list-form');
    const $productLitForm = $('form.product-lit-form');
    const $absSliders = $('.abs-slider');

    this.$scrollLinks.click((e) => {
      e.preventDefault();
      const $this = $(e.currentTarget);
      const id = $this.attr('href');
      const $elem = $(id);
      let offset = 0;
      if ($elem.length) {
        if ($elem.hasClass('page-section')) {
          const $prevSibs = $elem.prevAll('.page-section');
          $prevSibs.each(function() {
            offset += $(this).outerHeight(true);
          });
        } else {
          const $pSec = $elem.closest('.page-section');
          if ($pSec.length) {
            offset += $elem.position().top;
            const $prevSibs = $pSec.prevAll('.page-section');
            $prevSibs.each(function() {
              offset += $(this).outerHeight(true);
            });
          } else {
            offset += $elem.offset().top;
          }
        }
      }
      // offset += $('#site-header').outerHeight(true);
      gsap.to(this.$pWrap, {scrollTo: offset, duration: 1, ease: "expo.out"});
    });

    $courseListForm.on('submit', (e) => {
      e.preventDefault();
      var $this = $(e.currentTarget);
      var $select = $this.find('.courses-list');
      var val = $select.val();
      if (val && val.length) {
        window.location = val;
      }
    });

    $productLitForm.on('submit', (e) => {
      e.preventDefault();
      var $this = $(e.currentTarget);
      var $select = $this.find('.product-lit-select');
      var val = $select.val();
      if (val && val.length) {
        window.open(val);
      }
    });

    $absSliders.each(function(index) {
      const $this = $(this);
      // var $ss = $this.find('.slideshow-images');
      var time = $this.attr("data-timer");
      var arrows = ($this.attr("data-arrows") == 'true') ? {
          useContainer: true,
          selector: '.slider-arrow',
          prevClass: 'prev-arrow',
          nextClass: 'next-arrow',
      } : false;

      var dots = ($this.attr("data-dots") == 'true') ? {
          useContainer: true,
          selector: '.bullet-link',
          useParentIndex: true,
      } : false;

      var pauseHover = ($this.attr("data-pause-hover") == 'true') ? true : false;

      if (!time) {
        time = 7000;
      } else {
        time = Number(time);
      }

      var opts = {
        el: this,
        sliderIndex: index,
        container: '.abs-slider-wrap',
        selector: '.abs-slider',
        slideSelector: '.slide',
        timer: {
            time: time,
        },
        swipe: true,
        loop: true,
        indexButtons: dots,
        arrows: arrows,
        pauseOnHover: pauseHover,
      };
      var slider = new AbsSlider(opts);
      slider.init(opts).stopTimer();
      self.sliders.push({config: opts, slider: slider, $el: $this});
      // const dID = slider.$el.parents('.slideshow-container').attr('data-item-id');
    });


    cleanupEditorTags($contentBoxes);

    if ($secBGs.length) {
      this.$w.resize($.proxy(debounceResize((e) => {

        const width = $(window).width();

        $secBGs.each(function() {
          let $this = $(this);
          const type = $this.attr('data-type');

          if (type == 'responsive') {
            let size;

            if(width < 576) {
              size = 'xs';
            } else if(width >= 576 && width < 768) {
              size = 'sm';
            } else if (width >= 768 && width < 992) {
              size = 'md';
            } else if (width >= 992 && width < 1200) {
              size = 'lg';
            } else {
              size = 'xl';
            }

            const imgData = JSON.parse($this.attr('data-responsive'));

            loadImage($this, imgData[size], function() {
              applyImageCSS($this, imgData[size]);
            });
          }

        });
        }, 200)));
    }

    Social.init();
    YoutubeApi.init();
    VimeoApi.init();
    Navigation.init();
    MapSection.init();
    StoreLocations.init();

    TrainingLibSection.init();
    // AccordionSection.init();

    $(document.body).tooltip({
      selector: '[data-toggle="tooltip"]',
      html: true,
      trigger: 'hover focus',
      template: '<div class="tooltip"><div class="tooltip-inner"></div></div>',
    });

    return this;
  },
  onZipKeyDown(self, e) {
    // Save old value.
    var $this = $(this);
    var val = $this.val();
    if (!val || (parseInt(val) <= 99999 && parseInt(val) >= 0))
    $this.data("old", val);
  },
  onZipKeyUp(self, e) {
    // Check correct, else revert back to old value.
    var $this = $(this);
    var val = $this.val();
    if (!val || (parseInt(val) <= 99999 && parseInt(val) >= 0))
      ;
    else
      $this.val($this.data("old"));
  },
  bindZipFields($form) {
    var self = this;
    var $formWrap = $form.closest('.gform_wrapper');
    var downFunc = function(e) {
      self.onZipKeyDown.call(this, e);
    };
    var upFunc = function(e) {
      self.onZipKeyUp.call(this, e);
    };
    $formWrap.off('keydown', '.address_zip input[type="number"]', downFunc);
    $formWrap.on('keydown', '.address_zip input[type="number"]', downFunc);

    $formWrap.off('keyup', '.address_zip input[type="number"]', upFunc);
    $formWrap.on('keyup', '.address_zip input[type="number"]', upFunc);
  },
  setCurFormPage($form, curPage, initial) {
    let form = this.getFormObjFromElement($form);
    if (isNaN(curPage)) {
      curPage = 1;
    }
    if (!this.qString.form_page) {
      this.qString.form_page = curPage;
    }

    form.curPage = curPage;
    if (curPage != this.qString.form_page) {
      if (initial) {
        form.curPage = curPage = this.qString.form_page;
      } else {
        this.qString.form_page = form.curPage = curPage;
      }
    }

    let newUrl = location.protocol + '//' + location.host + location.pathname + '?' + qs.stringify(this.qString) + location.hash;
    if (location.href != newUrl && this.allowPageSkip) {
      history.pushState({url: newUrl}, 'Form Page ' + curPage, newUrl);
    }
    return this;
  },
  onWinLoad() {
    return this;
  },
  onResize(e) {
    const width = this.$w.width();
    this.$scrollContainer = (width >= 1200) ? $('.app-wrapper') : $('.page-wrap');
    return this;
  },
  scrolltoFormAnchor(form) {
    const width = this.$w.width();
    const $anchor = form.$form.prev('.gform_anchor');
    if (this.$scrollContainer.length && $anchor.length) {
      gsap.to(this.$scrollContainer, {scrollTo: {y: '#' + $anchor.attr('id')}, ease: "expo.out", duration: 1});
    }
    return this;
  },
};
