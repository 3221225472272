
// Facebook JS SDK
var appID = '385919242172345';
if (window.location.host.match('xcel.test') || window.location.host.match('localhost')) {
  appID = '2269195393324274';
} else if (window.location.host.match('xcel.magstage.com')) {
  appID = '1853791624749991';
} else {
  appID = '385919242172345';
}

window.fbAsyncInit = () => {
  FB.init({
    appId            : appID,
    autoLogAppEvents : true,
    xfbml            : true,
    version          : 'v3.2',
  });
};

(function(d, s, id){
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {return;}
  js = d.createElement(s); js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));


export default {
  socialLinkSelector: '.share-link',
  init() {
    $(document.body).on('click', this.socialLinkSelector, $.proxy(this.onSocialLinkClick, this));
    return this;
  },
  onSocialLinkClick(e) {
    var $this = $(e.currentTarget);
    if ($this.hasClass('facebook')) {
      e.preventDefault();
      this.shareFacebook($this.attr('href'));
    } else if ($this.hasClass('twitter')) {
      e.preventDefault();
      var $meta = $('head meta[name="twitter:title"]');
      var content = $meta.attr('content');
      this.shareTwitter('https://twitter.com/intent/tweet/?text=' + encodeURIComponent(content) + '&url=' + encodeURIComponent($this.attr('href')));
    } else if ($this.hasClass('linkedin')) {
      e.preventDefault();
      this.shareLinkedIn('https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent($this.attr('href')));
    }
    return this;
  },
  shareFacebook(url, callback) {
    FB.ui(
      {
        method: 'share',
        href: url,
      },
      // callback
      function(response) {
        if (response && !response.error_message) {
          if (typeof callback === 'function') {
              callback(response);
          }
        } else {
          alert('Error while posting.');
        }
      }
    );
    return this;
  },
  shareTwitter(url) {
    var width = 600;
    var height = 600;
    var screenWidth = $(window).width();
    var screenHeight = $(window).height();
    var left = (screenWidth / 2) - (width / 2),
      top = (screenHeight / 2) - (height / 2);

    window.open(url, '', "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
    return this;
  },
  shareLinkedIn(url) {
    var width = 600;
    var height = 600;
    var screenWidth = $(window).width();
    var screenHeight = $(window).height();
    var left = (screenWidth / 2) - (width / 2),
      top = (screenHeight / 2) - (height / 2);
    window.open(url, '', "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
    return this;
  },
}
