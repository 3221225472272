import 'jquery';
import HeaderMenu from './HeaderMenu';
import {gsap} from 'gsap';
import { ScrollToPlugin } from "ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

export default {
  headerSelector: '#site-header',
  revealSelector: '.hover-reveal, .widget ul.menu li.menu-item-has-children',
  navItemSelector: '#primary-navigation .level-1-item.toggle-item .level-1-link',
  scrollLinkSelector: 'a.scroll-link',
  pageWrapSelector: '.page-wrap',
  appWrapSelector: '.app-wrapper',
  init() {
    this.$w = $(window);
    this.$header = $(this.headerSelector);
    this.$revealHovers = $(this.revealSelector);
    this.$navToggleItems = $(this.navItemSelector);
    this.$scrollLinks = $(this.scrollLinkSelector);
    this.$pWrap = $(this.pageWrapSelector);
    this.$aWrap = $(this.appWrapSelector);

    this.headerMenu = new HeaderMenu(this.headerSelector);
    this.bindEvents();
  },
  bindEvents() {
    this.$navToggleItems.click($.proxy(this.onToggleClick, this));
    this.$revealHovers.on('mouseenter', $.proxy(this.onMouseEnter, this));
    this.$revealHovers.on('mouseleave', $.proxy(this.onMouseLeave, this));
    this.$scrollLinks.click($.proxy(this.onScrollLinkClick, this));
    this.$aWrap.scroll($.proxy(this.onScroll, this));
    this.$pWrap.scroll($.proxy(this.onScroll, this));
    return this;
  },
  isOverflown(element, dir = 'both') {
    switch(dir) {
      case 'y':
        return element.scrollHeight > element.clientHeight;
      case 'x':
        return element.scrollWidth > element.clientWidth;
      case 'both':
      default:
        return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
    }
  },
  onScroll(e) {
    var $elem = $(e.currentTarget);
    var isOF = this.isOverflown($elem[0], 'y');
    if (isOF) {
      $elem.addClass('is-overflown');
    } else {
      $elem.removeClass('is-overflown');
    }
  },
  onToggleClick(e) {
    e.preventDefault();
    let $this = $(e.currentTarget);
    let $navItem = $this.closest('.level-1-item.toggle-item');
    $navItem.siblings().removeClass('toggled');
    if ($navItem.hasClass('toggled')) {
      $navItem.removeClass('toggled');
    } else {
      $navItem.addClass('toggled');
    }
  },
  onMouseEnter(e) {
    const $this = $(e.currentTarget);
    $this.addClass('expanding');
    const $subMenu = $this.find(' > .sub-menu');
    const $link = $this.find('> a:first-child');
    if ($subMenu.length && $link.length) {
      const cHeight = $subMenu.outerHeight(true);
      const oHeight = $link.outerHeight(true);
      gsap.fromTo($this, {height: oHeight}, {height: cHeight + oHeight, duration: 0.5, ease: "expo.out"});
    }
  },
  onMouseLeave(e) {
    const $this = $(e.currentTarget);
    $this.removeClass('expanding');
    const $subMenu = $this.find(' > .sub-menu');
    const $link = $this.find('> a:first-child');
    if ($subMenu.length && $link.length) {
      const cHeight = $subMenu.outerHeight(true);
      const oHeight = $link.outerHeight(true);
      gsap.fromTo($this, {height: cHeight + oHeight}, {height: oHeight, duration: 0.5, ease: "expo.out"});
    }
  },
  onScrollLinkClick(e) {
    e.preventDefault();
    let $this = $(e.currentTarget);
    let href = $this.attr('href');
    const $elem = $(href);
    if (href == '#site-footer') {
      href = this.$pWrap[0].scrollHeight;
    }
    if ($elem.length) {
      gsap.to(this.$pWrap[0], {scrollTo: {y: href, offsetY: window.getHeaderOffset()}, duration: 1, ease: "expo.out"});
      this.headerMenu.closeMenu();
    }
  },
}
