import 'jquery';
import { gsap } from "gsap";

function AbsSlider(opts) {

  var defOpts = {
    options: {
      container: ".slider-container",
      selector: ".slider-track",
      slideSelector: ".slideshow-wrap",
      activeClass: "active",
      prevSwipeClass: "onLeft", // The class that positions the PREVIOUS slide to the LEFT of the current slide.
      nextSwipeClass: "onRight", // The class that positions the NEXT slide to the RIGHT of the current slide.
      dragThreshold: 3, // How many pixels before a touch event is considered the start of a swipe.
      velocityCheckTime: 1, // The amount of millisections to wait to grab the instant velocy. (Anything lower than ~4 will act as 0)
      slideMass: 0.2, //The weight of the slide. This number is used to simulate weight of the slide so that artificial friction can be applied to it using a natural decay function.
      decayTimeConstant: 100, // This number is far too compex to explain on one line but experiment with it.
      onTopClass: "onTop", // In the case that the slides overlap, this class is put on the slide that should have the higher z-index
      leaveDuration: 0.7, // How long the animation is when leaving the screen
      enterDuration: 0.7, // How long the animation is when entering the screen
      enterEase: "expo.out",
      leaveEase: "expo.out",
      activeIndex: 0,
      parentIndex: null,
      pauseOnHover: true,
      swipe: true,
      loop: true,
      timer: {
          time: 7000,
      },
      indexButtons: {
          useContainer: true, // True if the button element is not inside of the slider element. Container selector to search in.
          selector: ".bullet-link",
          useParentIndex: true, // Set to true if index buttons are wrapped
      },
      arrows: {
          useContainer: true,
          selector: '.slider-arrow',
          prevClass: 'prev-arrow',
          nextClass: 'next-arrow',
      },
    },
  };

  var slider = {
    options: opts,
    proxy: function (func) {
        var self = this;
        return function() {
            return func.apply(self, arguments);
        };
    },
    init: function (opts) {
        this.options = $.extend(this.options, opts);
        if (!this.options.el) {
            throw "AbsoluteSlider Component needs the 'el' property passed in the options.";
        }
        if (!this.options.selector) {
            throw "AbsoluteSlider Component needs a selector to find its element.";
        }
        this.setDefaults().setupControls().setupTouch().setActiveItems(this.options.activeIndex).startTimer();

        jQuery.subscribe("/slider/lastSlide", this.proxy(function(e, $el) {
          this.onSliderLastSlide(e, $el);
        }));
        jQuery.subscribe("/slider/otherSlide", this.proxy(function() {
          if (this.options.arrows && this.$arrows.length) {
            this.$arrows.filter("." + this.options.arrows.nextClass);
            // .removeClass("showing");
          }
        }));
        return this;
    },
    onSliderLastSlide(e, el) {
      var $el = $(el);
      if (this.options.childSliders && this.options.childSliders.length) {
        for(var i = 0; i < this.options.childSliders.length; i++) {
          var sliderEl = this.options.childSliders[i].$el.get(0);
            if (this.options.arrows && this.options.arrows.nextClass) {
              var $nextBtn = this.$arrows.filter("." + this.options.arrows.nextClass);
              if ($el.is(sliderEl)) {
                var nextTitle = $el.attr('data-next-title');
                // gsap.to($nextBtn, {autoAlpha: 1, duration: 0.3});
                $nextBtn.addClass("showing");
                var $btnText = $nextBtn.find('.next-slider-title');
                $btnText.text(nextTitle);
                break;
              }
            }

        }
      }
    },
    setupControls: function () {
        if (this.options.indexButtons) {
            if (this.options.indexButtons.useContainer) {
              this.$indexButtons = this.$el.closest(this.options.container).find(this.options.indexButtons.selector);
            } else {
              this.$indexButtons = this.$el.find(this.options.indexButtons.selector);
            }
            this.bindIndexButtons();
        }
        if (this.options.arrows) {
            if (this.options.arrows.useContainer) {
              this.$arrows = this.$el.closest(this.options.container).find(this.options.arrows.selector);
            } else {
              this.$arrows = this.$el.find(this.options.arrows.selector);
            }
            this.bindArrows();
        }
        if (this.options.pauseOnHover) {
          this.bindPauseHover();
        }
        return this;
    },
    setDefaults: function () {
        this.velocity = 0;
        this.amplitude = 0;
        this.totalDelta = 0;
        this.inputDelta = 0;
        this.isAnimating = false;
        this.passedThreshold = false;
        this.$el = $(this.options.el);
        this.$slides = this.$el.find(this.options.slideSelector);
        return this;
    },
    startTimer: function () {
        if (!this.$el.hasClass('hovering')) {
          if (this.options.timer) {
            this.slideTimer = setTimeout(this.proxy(function () {
              this.moveToNext();
            }), this.options.timer.time);
          }
        }
        return this;
    },
    stopTimer: function () {
        clearTimeout(this.slideTimer);
        // this.slideTimer = undefined;
        return this;
    },
    resetTimer: function () {
        return this.stopTimer().startTimer();
    },
    setupTouch: function () {
        this.$el.on("touchstart", this.proxy(this.onTouchStart));
        this.$el.on("touchend", this.proxy(this.onTouchEnd));
        return this;
    },
    bindPauseHover: function() {
      this.$el.closest('.page-section').hover(this.proxy(function(e) {
        this.$el.addClass('hovering');
        this.stopTimer();
      }), this.proxy(function(e) {
        this.$el.removeClass('hovering');
        this.startTimer();
      }));
      return this;
    },
    bindIndexButtons: function () {
        this.$indexButtons.on("click", this.proxy(this.onIndexClick));
        return this;
    },
    unbindIndexButtons: function () {
        this.$indexButtons.off("click", this.proxy(this.onIndexClick));
        return this;
    },
    bindArrows: function () {
        this.$arrows.on("click", this.proxy(this.onArrowClick));
        return this;
    },
    unbindArrows: function () {
        this.$arrows.off("click", this.proxy(this.onArrowClick));
        return this;
    },
    setSwipeSlides: function () {
        var prevIndex, nextIndex;
        if (this.options.activeIndex <= 0) {
            prevIndex = this.$slides.length - 1;
            nextIndex = this.options.activeIndex + 1;
        } else if (this.options.activeIndex >= this.$slides.length - 1) {
            nextIndex = 0;
            prevIndex = this.options.activeIndex - 1;
        } else {
            prevIndex = this.options.activeIndex - 1;
            nextIndex = this.options.activeIndex + 1;
        }
        this.swipeSlides = {
            $prev: this.$slides.eq(prevIndex),
            $next: this.$slides.eq(nextIndex),
            $active: this.$slides.eq(this.options.activeIndex),
            $all: $().add(this.$slides.get(prevIndex)).add(this.$slides.get(nextIndex)).add(this.$slides.get(this.options.activeIndex)),
        };
        this.swipeSlides.$prev.addClass(this.options.prevSwipeClass);
        this.swipeSlides.$next.addClass(this.options.nextSwipeClass);
        return this;
    },
    trackSwipeValues: function () {
        var now, elapsed, delta;
        now = Date.now();
        elapsed = now - this.timestamp;
        this.timestamp = now;
        delta = this.currentPos - this.lastX;
        var instantV = this.getInstantVelocity(delta, elapsed);
        this.velocity = this.getAverageVelocity(instantV);
        this.lastX = this.currentPos;
        this.lastPos = this.currentPos;
        return this;
    },
    getInstantVelocity: function (delta, elapsed) {
        return 1000 * delta / (1 + elapsed);
    },
    getAverageVelocity: function (instantV) {
        return 0.8 * instantV + 0.2 * this.velocity;
    },
    moveSlides: function (pos) {
        this.isAnimating = true;

        // Parallax swiping with different slide speeds.

        var $outside = $().add(this.swipeSlides.$prev).add(this.swipeSlides.$next);

        $outside.css({
          'transform': "translate3d(" + pos + "px,0,0)",
        });

        this.swipeSlides.$active.css({
          'transform': "translate3d(" + (pos/2) + "px,0,0)",
        });

        // Normal swiping with the same slide speed.
        // this.swipeSlides.$all.css({
        //   'transform': "translate3d(" + pos + "px,0,0)",
        // });
        return this;
    },
    onTouchStart: function (e) {
        e.stopPropagation();
        if (!this.isAnimating && this.options.swipe) {
            clearInterval(this.velocityInterval);
            this.startPos = this.currentPos = this.lastX = this.lastPos = e.originalEvent.touches[0].clientX;
            this.totalDelta = 0;
            this.inputDelta = 0;
            this.timestamp = Date.now();
            this.velocityInterval = setInterval(this.proxy(this.trackSwipeValues), this.options.velocityCheckTime);
            this.snapIncrement = this.$el.outerWidth();
            this.$el.on("touchmove", this.proxy(this.onTouchMove));
            this.stopTimer();
        }
        return this;
    },
    isBefore() {
      if (this.options.activeIndex == 0 && this.totalDelta > 0) {
        return true;
      }
      return false;
    },
    isAfter() {
      if (this.options.activeIndex == this.$slides.length -1 && this.totalDelta < 0) {
        return true;
      }
      return false;
    },
    onTouchMove: function (e) {
        e.stopPropagation();
        if (this.options.swipe) {
            this.currentPos = e.originalEvent.touches[0].clientX;
            this.lastPos = this.currentPos - this.lastX;
            this.totalDelta = this.currentPos - this.startPos;

            if (this.isBefore() && !this.options.loop) {
              this.inputDelta = this.totalDelta * (1 - (this.velocity / -this.snapIncrement));
              // console.log("After InputDelta: ", this.inputDelta, this.totalDelta);
            }

            if (this.isAfter() && !this.options.loop) {
              this.inputDelta = this.totalDelta * (this.snapIncrement - this.totalDelta) / this.snapIncrement;
              // console.log("After InputDelta: ", this.inputDelta, this.totalDelta);
            }
            if (Math.abs(this.totalDelta) > this.options.dragThreshold || this.passedThreshold) {
                this.passedThreshold = true;
                this.setSwipeSlides();
                e.preventDefault();
                this.moveSlides(this.totalDelta);
            }
        }
        return this;
    },
    onTouchEnd: function (e) {
        e.stopPropagation();
        this.$el.off("touchmove");
        clearInterval(this.velocityInterval);
        if (this.isAnimating) {
            this.destPos = Math.round((this.totalDelta + (this.options.slideMass * this.velocity)) / this.snapIncrement) * this.snapIncrement;
            if (Math.abs(this.destPos) > this.snapIncrement) {
                this.destPos = this.snapIncrement * this.destPos / Math.abs(this.destPos);
            }
            this.amplitude = this.destPos - this.totalDelta;
            this.timestamp = Date.now();
            requestAnimationFrame(this.proxy(function () {
                this.autoDecaySlideSpeed();
            }));
            if (this.amplitude > 0) {
                e.preventDefault();
            }
        }
        return this;
    },
    getDecayDelta: function (elapsed) {
        var delta = -this.amplitude * Math.exp(-elapsed / this.options.decayTimeConstant);
        return delta;
    },
    autoDecaySlideSpeed: function () {
        var elapsed, delta;
        if (this.amplitude !== 0) {
            this.isAnimating = true;
            this.totalDelta = this.currentPos - this.startPos;
            elapsed = Date.now() - this.timestamp;
            delta = this.getDecayDelta(elapsed);
            this.lastX = this.currentPos;
            this.lastPos = this.currentPos;
            if (Math.abs(delta) > 0.5) {
                  this.currentPos = this.destPos + delta;
                  this.moveSlides(this.currentPos);
                  requestAnimationFrame(this.proxy(function () {
                      this.autoDecaySlideSpeed();
                  }));
            } else {
                this.currentPos = this.destPos;
                this.moveSlides(this.currentPos);
                this.autoSlideEnded();
            }
        }
        return this;
    },
    autoSlideEnded: function () {
        this.setActiveSlide();
        this.velocity =
        this.amplitude =
        this.totalDelta =
        this.inputDelta =
        this.destPos =
        this.currentPos =
        this.startPos =
        this.lastPos =
        this.lastX = 0;
    },
    setActiveSlide: function () {
        if (Math.abs(this.destPos) > 0) {
            if (this.totalDelta > 0) {
                this.swipeSlides.$active = this.swipeSlides.$prev;
            } else if (this.totalDelta < 0) {
                this.swipeSlides.$active = this.swipeSlides.$next;
            }
        }
        this.setActiveItems(this.swipeSlides.$active.index());
        return this;
    },
    onIndexClick: function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (!this.isAnimating) {
            this.resetTimer()
            var $this = $(e.currentTarget), index = (this.options.indexButtons.useParentIndex) ? $this.parent().index() : $this.index();
            this.moveToIndex(index, (index < this.options.activeIndex), this.proxy(function () {
                this.setActiveItems(index);
            }));
        }
    },
    onArrowClick: function (e) {
        e.preventDefault();
        e.stopPropagation();
        var $this = $(e.currentTarget);
        if (!this.isAnimating) {
          this.resetTimer()
          if ($this.hasClass(this.options.arrows.nextClass)) {
              this.moveToNext();
          } else if ($this.hasClass(this.options.arrows.prevClass)) {
              this.moveToPrev();
          }
        }
        // gsap.to($this, {autoAlpha: 0, duration: 0.3});
        if ($this.hasClass('showing')) {
          $this.removeClass("showing");
        }
        return this;
    },
    moveToPrev: function () {
        var index = (this.options.activeIndex <= 0) ? this.$slides.length - 1 : this.options.activeIndex - 1;
        this.moveToIndex(index, true, this.proxy(function () {
            this.setActiveItems(index);
        }));
        return this;
    },
    moveToNext: function () {
        var index = (this.options.activeIndex >= this.$slides.length - 1) ? 0 : this.options.activeIndex + 1;
        this.moveToIndex(index, false, this.proxy(function () {
          this.setActiveItems(index);
        }));
        return this;
    },
    moveToIndex: function (index, prev, callback) {
        if (index !== this.options.activeIndex) {
            this.stopTimer();
            var $old = this.$slides.eq(this.options.activeIndex);
            var $new = this.$slides.eq(index);
            $new.addClass(this.options.activeClass);
            $old.addClass(this.options.onTopClass);
            if (prev) {
              this.leaveNext($old);
              this.enterPrev($new, callback);
            } else {
              this.leavePrev($old);
              this.enterNext($new, callback);
            }
          }
        return this;
    },
    setActiveItems: function (index) {
        this.isAnimating = false;
        if (this.options.indexButtons && this.options.indexButtons.useParentIndex) {
          if (this.$indexButtons && this.$indexButtons.length) {
             this.$indexButtons.parent().removeClass(this.options.activeClass);
             this.$indexButtons.eq(index).parent().addClass(this.options.activeClass);
          }
        } else {
          if (this.$indexButtons && this.$indexButtons.length) {
             this.$indexButtons.removeClass(this.options.activeClass);
             this.$indexButtons.eq(index).addClass(this.options.activeClass);
          }
        }

        this.$slides.removeClass(this.options.prevSwipeClass + " " + this.options.nextSwipeClass + " " + this.options.activeClass + " " + this.options.onTopClass).attr("style", "");
        this.$slides.eq(index).addClass(this.options.activeClass);
        // var oldIndex = this.options.activeIndex * 1;
        this.options.activeIndex = index;
        this.resetTimer();
        if (!this.options.loop && this.options.arrows) {
          if (index == 0) {
            this.$arrows.filter("." + this.options.arrows.prevClass).addClass("disabled");
          } else {
            this.$arrows.filter("." + this.options.arrows.prevClass).removeClass("disabled");
          }
        }

        if (!this.options.loop && this.options.arrows) {
          if (index == this.$slides.length - 1) {
            this.$arrows.filter("." + this.options.arrows.nextClass).addClass("disabled");
          } else {
            this.$arrows.filter("." + this.options.arrows.nextClass).removeClass("disabled");
          }
        }

        // if (index == this.$slides.length - 1) {
        //   $.publish("/slider/lastSlide", this.$el);
        // } else {
        //   $.publish("/slider/otherSlide", this.$el);
        // }

        // if (oldIndex !== index) {
        //     console.log("Slide Changed!", this.$el, oldIndex, index);
        // }
        this.passedThreshold = false;
        return this;
    },
    leavePrev: function ($elem, callback) {
        this._fromTo({$elem: $elem, from: "0%", to: "-100%", ease: this.options.leaveEase, duration: this.options.leaveDuration, callback: callback});
        return this;
    },
    leaveNext: function ($elem, callback) {
        this._fromTo({$elem: $elem, from: "0%", to: "100%", ease: this.options.leaveEase, duration: this.options.leaveDuration, callback: callback});
        return this;
    },
    enterPrev: function ($elem, callback) {
        // $elem.addClass('onTop');
        this._fromTo({$elem: $elem, from: "-100%", to: "0%", ease: this.options.enterEase, duration: this.options.enterDuration, callback: callback});
        return this;
    },
    enterNext: function ($elem, callback) {
        // $elem.addClass('onTop');
        this._fromTo({$elem: $elem, from: "100%", to: "0%", ease: this.options.enterEase, duration: this.options.enterDuration, callback: callback});
        return this;
    },
    _fromTo: function (options) {
        gsap.fromTo(options.$elem, {x: options.from}, {x: options.to, ease: options.ease, duration: options.duration, onComplete: this.proxy(function () {
            if (options.callback) {
              options.callback.call();
            }
        })});
        this.isAnimating = true;
        return this;
    },
  };
  if (opts && !opts.options) {
    opts = {
      options: opts,
    };
  }
  var newSlider = $.extend(true, {}, slider, defOpts, opts);
  return newSlider;
}

export default AbsSlider;
